import { FormValidationType } from '../../../types/interface/form.interface'
import { FORM_ID, FORMAT_DAY_MONTH_YEAR_DOT } from '../../../constants'
import Joi from '@hapi/joi'
import { legalAidDateValidation } from '../../../validation/fieldsVS'
import format from 'date-fns/format'
import { FlowName } from '../../../config'
import quoteAndBuyFormValidation from '../qb/validation'

export interface BrandFormValidationInterface {
  [FlowName.QUOTE_AND_BUY]: FormValidationType
  [FlowName.MY_PAGES]: FormValidationType
}

// const quoteAndBuyFormValidation = (brandConfig: Record<string, unknown>): FormValidationType => (
const myPagesFormValidation = (brandConfig: Record<string, unknown>): FormValidationType => ({
  [FORM_ID.ACCIDENT]: {
    vehicle: ({ min, max }) =>
      Joi.object().keys({
        address: Joi.string().max(100).required().empty().messages({
          'string.base': `accident.form.address.validation.base`,
          'string.empty': `accident.form.address.validation.base`,
          'string.max': `accident.form.address.validation.base`,
        }),
        city: Joi.string().max(100).required().empty().messages({
          'string.base': `accident.form.city.validation.base`,
          'string.empty': `accident.form.city.validation.base`,
          'string.max': `accident.form.city.validation.base`,
        }),
        country: Joi.string().max(100).required().empty().messages({
          'string.base': `accident.form.country.validation.base`,
          'string.empty': `accident.form.country.validation.base`,
          'string.max': `accident.form.country.validation.base`,
        }),
        occurDate: legalAidDateValidation(min, max),
        time: Joi.date().required().empty().max('now').messages({
          'date.base': `accident.form.time.validation.base`,
          'date.empty': `accident.form.time.validation.base`,
          'date.max': `accident.form.time.validation.future`,
        }),
      }),
  },
  [FORM_ID.COMPENSATION]: {
    vehicle: Joi.object().keys({
      compensation: Joi.string()
        .required()
        .regex(/^[0-9 ]*$/)
        .min(1)
        .max(20)
        .messages({
          'string.base': `Vennligst legg inn et tall.`,
          'string.min': `Vennligst legg inn et tall.`,
          'string.max': `Vennligst legg inn et tall.`,
          'string.pattern.base': `Vennligst legg inn et tall.`,
          'any.required': ``,
        }),
    }),
  },
  [FORM_ID.ADDITIONAL_INFO]: {
    vehicle: Joi.object()
      .optional()
      .keys({
        additionalInfo: Joi.string().max(10000).optional().allow(null).messages({
          'string.max': `Teksten er for lang.`,
        }),
      }),
  },
  [FORM_ID.DATE]: {
    vehicle: ({ min, max }) =>
      Joi.object().keys({
        date: legalAidDateValidation(min, max),
      }),
  },
  [FORM_ID.STOLEN_AGE_VALUE]: {
    vehicle: Joi.object().keys({
      age: Joi.string()
        .required()
        .regex(/^[0-9 ]*$/)
        .min(0)
        .max(3)
        .messages({
          'string.base': `stolenAgeValue.form.age.validation.base`,
          'string.empty': `stolenAgeValue.form.age.validation.base`,
          'string.min': `stolenAgeValue.form.age.validation.base`,
          'string.max': `stolenAgeValue.form.age.validation.base`,
          'any.required': ``,
        }),
      value: Joi.string()
        .required()
        .regex(/^[0-9 ]*$/)
        .min(1)
        .max(6)
        .messages({
          'string.base': `stolenAgeValue.form.value.validation.base`,
          'string.min': `stolenAgeValue.form.value.validation.base`,
          'string.max': `stolenAgeValue.form.value.validation.base`,
          'string.pattern.base': `stolenAgeValue.form.value.validation.base`,
          'any.required': ``,
        }),
    }),
  },
  [FORM_ID.POLICE_CASE]: {
    vehicle: Joi.object()
      .optional()
      .keys({
        caseNumber: Joi.string().min(6).max(13).messages({
          'string.base': `policeCase.form.caseNumber.validation.base`,
          'string.min': `policeCase.form.caseNumber.validation.base`,
          'string.max': `policeCase.form.caseNumber.validation.base`,
        }),
      }),
  },
  [FORM_ID.REGISTRATION_NUMBER]: {
    vehicle: Joi.object().keys({
      registrationNum: Joi.string().required().empty().max(10).min(2).messages({
        'string.base': 'claims.collision.registrationNum.form.registrationNum.validation.base',
        'string.empty': 'claims.collision.registrationNum.form.registrationNum.validation.base',
        'string.min': 'claims.collision.registrationNum.form.registrationNum.validation.base',
        'string.pattern.base':
          'claims.collision.registrationNum.form.registrationNum.validation.base',
        'any.required': '',
      }),
    }),
  },
  [FORM_ID.MORE_DETAILS]: {
    vehicle: Joi.object()
      .optional()
      .keys({
        moreDetails: Joi.string().max(10000).optional().allow(null).messages({
          'string.max': `Teksten er for lang.`,
        }),
      }),
  },
  [FORM_ID.OBJECT]: {
    vehicle: Joi.object().keys({
      object: Joi.string().max(100).messages({
        'string.max': `Teksten er for lang.`,
      }),
    }),
  },
  [FORM_ID.POLICY_VERIFY]: {
    vehicle: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'policyVerify.form.policyDate.validation.base',
            'date.empty': 'policyVerify.form.policyDate.validation.base',
            'date.max': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'policyVerify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'policyVerify.form.email.validation.base',
            'string.email': 'policyVerify.form.email.validation.base',
            'string.empty': 'policyVerify.form.email.validation.base',
            'any.required': 'policyVerify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'policyVerify.form.phone.validation.base',
            'string.length': 'policyVerify.form.phone.validation.base',
            'string.pattern.base': 'policyVerify.form.phone.validation.base',
            'any.required': 'policyVerify.form.phone.validation.base',
          }),
      })
    },
    travel: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'policyVerify.form.policyDate.validation.base',
            'date.empty': 'policyVerify.form.policyDate.validation.base',
            'date.max': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'policyVerify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'policyVerify.form.email.validation.base',
            'string.email': 'policyVerify.form.email.validation.base',
            'string.empty': 'policyVerify.form.email.validation.base',
            'any.required': 'policyVerify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'policyVerify.form.phone.validation.base',
            'string.length': 'policyVerify.form.phone.validation.base',
            'string.pattern.base': 'policyVerify.form.phone.validation.base',
            'any.required': 'policyVerify.form.phone.validation.base',
          }),
      })
    },
    content: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'policyVerify.form.policyDate.validation.base',
            'date.empty': 'policyVerify.form.policyDate.validation.base',
            'date.max': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `policyVerify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'policyVerify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'policyVerify.form.email.validation.base',
            'string.email': 'policyVerify.form.email.validation.base',
            'string.empty': 'policyVerify.form.email.validation.base',
            'any.required': 'policyVerify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'policyVerify.form.phone.validation.base',
            'string.length': 'policyVerify.form.phone.validation.base',
            'string.pattern.base': 'policyVerify.form.phone.validation.base',
            'any.required': 'policyVerify.form.phone.validation.base',
          }),
      })
    },
  },
  [FORM_ID.POLICY_CHANGE]: {
    vehicle: Joi.object().keys({
      CarPackage: Joi.string().required().max(4).messages({
        'string.base': `policyChange.form.coverageType.validation.base`,
        'string.empty': `policyChange.form.coverageType.validation.base`,
        'any.required': `required`,
      }),
      CarComprehensiveDeductible: Joi.string()
        .optional()
        .valid(null, '', '4000', '6000', '8000', '12000', '16000')
        .messages({
          'string.base': `policyChange.form.CarComprehensiveDeductible.validation.base`,
          'string.valid': `policyChange.form.CarComprehensiveDeductible.validation.base`,
        }),
      AnnualMileage: Joi.number().required().empty().min(1).messages({
        'number.base': `policyChange.form.AnnualMileage.validation.base`,
        'number.empty': `policyChange.form.AnnualMileage.validation.base`,
        'number.min': `policyChange.form.AnnualMileage.validation.base`,
        'any.required': `required`,
      }),
      CarBonus: Joi.string().required().messages({
        'string.base': `policyChange.form.CarBonus.validation.base`,
        'string.empty': `policyChange.form.CarBonus.validation.base`,
        'any.required': `required`,
      }),
      YoungDrivers: Joi.boolean().required().messages({
        'date.base': `policyChange.form.YoungDrivers.validation.base`,
        'date.empty': `policyChange.form.YoungDrivers.validation.base`,
        'any.required': `required`,
      }),
    }),
    travel: Joi.object().keys({
      TravelCoverage: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      TravelCoverArea: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      TravelPreviousClaims: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
    content: Joi.object().keys({
      SumInsuredContents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      Alarm: Joi.boolean().optional().allow(null),
      DeductibleContents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      Residents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
  },
  ...quoteAndBuyFormValidation(brandConfig),
})

export default myPagesFormValidation
