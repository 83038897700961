import { useContext, useEffect } from 'react'
import { useSelector } from '@xstate/react'
import { Events } from '../../../FSM/shared/constants'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { MachineConfigType } from '../../../FSM/shared/config'

import { getBackButtonText, getProductName } from '@dg-util'
import { ProductName } from '../../../types/interface/quote.interface'

import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'
import { QuoteV2Type } from '../../../types/ApiV2'
import { Context } from '@dg-shared/ContextProvider'
import {
  SELECTED_QUOTE_ADDRESS,
  SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER,
} from '../../../constants'
import useReactQueryDatasource from '../../../hooks/shared/useReactQueryDatasource'
import { AddressValues } from '../../../types/interface/map.interface'
import { FlowName } from '../../../config'
import { switchFSM } from '@dg-util/productHandler'
import { FSMService } from '../../../FSM'

interface HookReview {
  loading: boolean
  productName: ProductName
  quote: Partial<QuoteV2Type> | null
  getBonusText(value: string): string | null
  btnBackText: string
  breadcrumbsBackHandler(): void
  errorInsurely: string | null
}

const getErrorKey = (transition: string, errors: Array<string>): string | null => {
  if (errors.indexOf(transition) !== -1) return errors[errors.indexOf(transition)]

  return null
}

const useLogic = (service: FlowServiceType): HookReview => {
  const transitionType = useSelector(service, (state) => state.event.type)
  const isMyPages = service.state?.context?.prevConfig?.flow === FlowName.MY_PAGES
  const regularBtnBackText = useSelector(service, ({ value }) =>
    getBackButtonText(service.machine.config as MachineConfigType, value)
  )
  const btnBackText = isMyPages ? 'back' : regularBtnBackText
  const breadcrumbsBackHandler = () => {
    isMyPages
      ? switchFSM({
          targetStateValue: service.state.context.prevConfig.stateValue,
          targetFlow: service.state.context.prevConfig.flow,
          targetProduct: service.state.context.prevConfig.product,
          FSMService: FSMService,
        })
      : service.send(Events.BACK)
  }

  const { quote, isLoading, initQuote, updateQuote, quoteId } = useReactQueryQuote()
  const { productProps, isLoading: isProductPropsLoading } = useReactQueryDatasource()

  const { context } = useContext(Context)
  const selectedQuoteMotorRegistrationNumber = context[
    SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER
  ] as string
  const selectedQuoteAddress = context[SELECTED_QUOTE_ADDRESS] as AddressValues

  // Init Quote if it is not initiated
  useEffect(() => {
    if (!quoteId) {
      // Init Quote for Vehicle product
      ;(async () =>
        await initQuote({ MotorRegistrationNumber: selectedQuoteMotorRegistrationNumber }))()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId])

  // Update quote with address for Content quote type
  useEffect(() => {
    if (quote) {
      ;(async () => {
        await updateQuoteWithAddress(quote)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote])

  const shouldUpdateQuoteAddress = (quote?: Partial<QuoteV2Type>) => {
    if (!quote) {
      // Do not require an update if there is no quote
      return false
    }

    const product = getProductName()

    if (![ProductName.CONTENT].includes(product)) {
      // Don't require address update for other than CONTENT products
      return false
    }

    // Check if address1, city and postNumber were changed
    const { address1, city } = selectedQuoteAddress

    if (quote.Address1 === address1 && quote.City === city) {
      // No address1, city change. Don't require address update
      return false
    }

    // Update the address otherwise
    return true
  }

  const updateQuoteWithAddress = async (quote: Partial<QuoteV2Type>) => {
    // Check if quote requires an address update
    if (shouldUpdateQuoteAddress(quote)) {
      const { address1, city, postNumber } = selectedQuoteAddress

      return await updateQuote({
        Address1: address1,
        City: city,
        PostNumber: postNumber,
      })
    }
  }

  const getBonusText = (bonusValue: string): string => {
    return productProps?.bonus?.values.find((item) => item.name === bonusValue).value
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return {
    loading: isLoading || isProductPropsLoading,
    quote,
    getBonusText,
    productName: getProductName(),
    btnBackText: btnBackText,
    errorInsurely: getErrorKey(transitionType, [
      Events.INSURELY_FAILURE_BANK_ID,
      Events.INSURELY_NO_OFFERS_FOUND,
    ]),
    breadcrumbsBackHandler,
  }
}

export default useLogic
