import React, { FC, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import clsx from 'clsx'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'

//TODO: Remove this code from the current component
//TODO: Propagate the translations from the top
import { useTranslate } from '../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      position: 'relative',
      margin: 0,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.dark,
      ...theme.brand.assets.layout?.customLabelRadio,
      fontSize: '1rem',
      '&:first-child': {
        borderRight: 'none',
      },
      '&.checked': {
        background: theme.brand.colors.main,
      },
    },

    label: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1rem',
      position: 'absolute',
      color: theme.palette.primary.dark,
      ...theme.brand.typography.overrides?.ownerRadioOverride,
    },
    legend: {
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    control: {
      paddingBottom: theme.spacing(2),
    },
    inputRadio: {
      borderRadius: 0,
      width: '70px',
      height: '30px',
      backgroundColor: theme.palette.primary.contrastText,
      ...theme.brand.assets.layout?.customInputRadio,
      padding: '5px',
      '&$checked': {
        color: 'red',
      },
    },
    icon: {
      borderRadius: 0,
    },
    checkedIcon: {
      borderRadius: 0,
    },
    checked: {
      backgroundColor: theme.palette.primary.main,
      '&:hover ': {
        backgroundColor: `${theme.brand.colors.main} !important`,
      },
      '&, & + $label': {
        '& > span': {
          BorderRight: 0,
        },
        color: theme.palette.primary.contrastText,
        ...theme.brand.assets.layout?.checkedLabel,
      },
    },
  })
)

interface RFFFormControlRadioProps extends FieldRenderProps<unknown> {
  fieldLabel: string
  fieldName: string
}

type InputValueType = '1' | '2'

const RFFFormControlRadio: FC<RFFFormControlRadioProps> = ({
  input,
  meta,
  fieldLabel,
  fieldName,
  ...props
}) => {
  const { t } = useTranslate()
  const classes = useStyles()
  const testId = props?.testId
  const isError: boolean = (!!meta.error || meta.submitError) && !meta.pristine
  const [checked, setChecked] = useState<InputValueType>()

  const checkedClass = {
    yes: checked === '1' ? 'checked' : '',
    no: checked === '2' ? 'checked' : '',
  }

  return (
    <FormControl id={testId} component='fieldset' className={classes.control} error={isError}>
      <FormLabel component='legend' className={classes.legend}>
        {fieldLabel}
      </FormLabel>

      <RadioGroup
        name={fieldName}
        onChange={(ev) => {
          setChecked(ev.target.value as InputValueType)
          input.onChange(ev)
        }}
        value={input.value}
        aria-label={fieldLabel}
        row
      >
        <FormControlLabel
          classes={{
            root: `${classes.root} ${checkedClass.yes}`,
            label: classes.label,
          }}
          key={`${fieldName}_1`}
          label={t(props.options.yes.text)}
          value={props.options.yes.value}
          control={
            <Radio
              id={`${testId}_${props.options.yes.text}`}
              classes={{ root: classes.inputRadio, checked: classes.checked }}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
            />
          }
        />
        <FormControlLabel
          classes={{
            root: `${classes.root} ${checkedClass.no}`,
            label: classes.label,
          }}
          key={`${fieldName}_2`}
          label={t(props.options.no.text)}
          value={props.options.no.value}
          control={
            <Radio
              id={`${testId}_${props.options.yes.text}`}
              classes={{ root: classes.inputRadio, checked: classes.checked }}
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
            />
          }
        />
      </RadioGroup>
      {isError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default RFFFormControlRadio
