import React, { FC, useState } from 'react'
import { Card, CardContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T, FORM_ID } from '../../../constants'
import { ExternalSubmitBtn } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import DetailedInfoShared from '@dg-shared/DetailedInfoShared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { reactQueryErrorHandler, setSessionStorageAuthItem } from '@dg-util'
import { Events } from '../../../FSM/shared/constants'
import { FSMService } from '../../../FSM'
import apiV2 from '../../../service/apiV2.service'
import { SESSION_ID_KEY } from '../../../config'
import { UserAuthInput } from '../../../types'

export const useAuthRegularStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    errorMessage: {
      marginBottom: theme.spacing(3),
    },
    submitBtn: {
      margin: `0 auto`,
    },
    infoCard: {
      overflow: 'visible',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    disclaimer: {
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    additionalInfo: {
      marginTop: theme.spacing(1.5),
      textAlign: 'center',
      '& a': {
        color: 'inherit',
      },
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const AuthAgent: FC<{
  handleLoading(isLoading: boolean): void
  handleError(agentError: string): void
}> = ({ handleLoading, handleError }) => {
  const { t } = useTranslate(T.AUTH)
  const classes = useAuthRegularStyles()
  const [infoOpened, setInfoOpened] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const detailsText = {
    title: t('form.ssn.details.title'),
    content: t('form.ssn.details.content'),
    link: t('form.ssn.details.link'),
  }

  const handleAuth = async (data: UserAuthInput) => {
    // const reCaptchaToken = await getReCaptchaToken(isReCaptchaReady, CONFIG.RECAPTCHA_SITE_KEY)
    handleLoading(true)

    try {
      const { token, kid, agentId } = await apiV2.agentPersonLogin(data, {})

      if (token) {
        FSMService.flowService.send(Events.NEXT)
        setSessionStorageAuthItem(token, kid, agentId)
        // Remove one time session ID
        sessionStorage.removeItem(SESSION_ID_KEY)
      } else {
        handleError(Events.AUTH_ERROR)
      }
    } catch (error) {
      reactQueryErrorHandler(error, FSMService.flowService)
    }
    handleLoading(false)
  }

  return (
    <>
      <section data-testid='auth-regular-wrapper-first' className={classes.wrapper}>
        {infoOpened && (
          <Card className={classes.infoCard}>
            <CardContent>
              <DetailedInfoShared hideDetails={() => setInfoOpened(false)} text={detailsText} />
            </CardContent>
          </Card>
        )}
        <FormBuilder
          id={FORM_ID.AUTH}
          submitHandler={handleAuth}
          handleBtnDisabled={setBtnDisabled}
          customLabelFunc={setInfoOpened}
          customSubmit
        />
      </section>
      <section data-testid='auth-regular-wrapper-second' className={classes.textWrapper}>
        <ExternalSubmitBtn
          text={t(`form.submitText`)}
          targetId={FORM_ID.AUTH}
          disabled={btnDisabled}
          customClassName={classes.submitBtn}
        />
      </section>
    </>
  )
}

export default AuthAgent
