import React, { FC } from 'react'
import { Typography, FSMBtnNext, CTA, withHidden, LMBgHeader, LDCardTwoCol } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import Loading from '../../qb/Loading/Page'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { LOGO_TEXT, FORMAT_DAY_MONTH_YEAR_DOT, TRANSLATIONS as T } from '../../../constants'
import { logoMax, logoMin } from 'BrandedImages'
import { CTA_TYPE_POLICY_DONE } from '../../../constants/cta-constants'
import format from 'date-fns/format'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import { FSMService } from '../../../FSM'
import { Events } from '../../../FSM/shared/constants'
import { getDataComponentName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    button: {
      ...theme.brand.assets.layout?.ctaOverride,
    },
    annotation: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    footer: {
      justifyContent: 'center',
      margin: '0 !important',
      '& > a': {
        textDecoration: 'none',
        color: theme.brand.colors.secondary,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const PagePolicyDone: FC<CommonPageProps> = () => {
  const { t } = useTranslate(T.POLICY_DONE)
  const classes = useStyles()
  const { policy, isLoading } = useReactQueryPolicy()
  const startDate: string = policy?.InsuredFrom
    ? format(new Date(policy.InsuredFrom), FORMAT_DAY_MONTH_YEAR_DOT)
    : ''

  const clickHandler = async () => {
    FSMService.flowService.send(Events.NEXT)
  }

  const body = (
    <>
      <Typography testId='done-annotation' className={classes.annotation}>
        {t('annotation')}
      </Typography>
      <CTA context={CTA_TYPE_POLICY_DONE} containerClass={classes.footer} />
      <FSMBtnNext
        customClassName={classes.button}
        buttonText={t(`finished`)}
        handleClick={clickHandler}
      />
    </>
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        headerSubTitle={t('subtitle', { startDate })}
        textLogoAlt={LOGO_TEXT}
        brandPageBackground='secondaryMobile'
        imageLogo={logoMin}
      >
        {body}
      </MobileScreen>
      <DesktopScreen
        textLogoAlt={t('title')}
        imageLogo={logoMax}
        brandPageBackground='secondary'
        brandPageBackgroundCard='secondaryCard'
        isReverse
      >
        <div data-componentname={getDataComponentName(__filename)}>
          <Typography type='h1'>{t('title')}</Typography>
          <Typography type='h3'>{t('subtitle', { startDate })}</Typography>
        </div>
        {body}
      </DesktopScreen>
    </>
  )
}

export default PagePolicyDone
