import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ErrorStylesProps } from '../../types/interface/styles.interface'

export const useCtaStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: (props: ErrorStylesProps) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },
      '& > a': {
        textDecoration: 'none',
        color: theme.brand.colors.secondary,
        '&:hover': {
          textDecoration: props && props.externalLink ? 'underline' : 'none',
        },
      },
    }),
    link: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    disclaimer: {
      textAlign: 'center',
      color: theme.brand.colors.textSecondary,
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
  })
)
