import { Typography } from '@dg-shared/index'
import React, { FC } from 'react'
import { getPolicyDates } from '@dg-util'
import { PolicyV2Type } from '../../types/ApiV2'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      marginTop: theme.spacing(2),
    },
  })
)

const PolicyInsuranceDatesText: FC<{
  policy: Partial<PolicyV2Type>
  classDate?: string
}> = ({ policy, classDate }) => {
  const classes = useStyles()

  if (!policy) {
    return null
  }

  const { dateFrom, dateTo } = getPolicyDates(policy as PolicyV2Type)

  return (
    <Typography
      className={[classes.date, classDate].join(' ')}
      color='textSecondary'
      testId={'policy-date'}
    >
      {`${dateFrom} - ${dateTo ? dateTo : '...'}`}
    </Typography>
  )
}

export default PolicyInsuranceDatesText
