import { PartnerName, ProductName } from '../types/interface/quote.interface'

export enum EnvType {
  ENV_LOCAL = 'local',
  ENV_TEST = 'test',
  ENV_DEV = 'development',
  ENV_ACC = 'acceptance',
  ENV_PROD = 'production',
}

export const ENV = process.env.ENV as EnvType

export const IS_LOCAL = ENV === EnvType.ENV_LOCAL
export const IS_DEVELOPMENT = ENV === EnvType.ENV_DEV
export const IS_ACCEPTANCE = ENV === EnvType.ENV_ACC
export const IS_PRODUCTION = ENV === EnvType.ENV_PROD

const ALL_BRANDS_TEST_GTM_ID = 'GTM-K89HQ3N'
const INSTABANK_PROD_GTM_ID = 'GTM-NZ339RM'
const NORD_PROD_GTM_ID = 'GTM-NZ339RM'
const INSTABANK_PROD_TRAVEL_GTM_ID = 'GTM-WX8ZQJ4'
const CIRCKEK_PROD_GTM_ID = 'GTM-5WVJNH2'
const AUDI_PROD_GTM_ID = 'GTM-TZ4J6FQ'
const VOLKSWAGEN_PROD_GTM_ID = 'GTM-MHJ8G84'
const SKODA_PROD_GTM_ID = 'GTM-M73BLRD'

const GTM_CONFIG_MAP: Record<
  PartnerName,
  Record<string, Record<ProductName, Record<EnvType, string>>>
> = {
  [PartnerName.instabank]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: INSTABANK_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: INSTABANK_PROD_TRAVEL_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: INSTABANK_PROD_TRAVEL_GTM_ID,
      },
    },
  },
  [PartnerName.nord]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: NORD_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: NORD_PROD_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: NORD_PROD_GTM_ID,
      },
    },
  },
  [PartnerName.circlek]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: CIRCKEK_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: CIRCKEK_PROD_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: CIRCKEK_PROD_GTM_ID,
      },
    },
  },
  [PartnerName.volkswagen]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: VOLKSWAGEN_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: VOLKSWAGEN_PROD_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: VOLKSWAGEN_PROD_GTM_ID,
      },
    },
  },
  [PartnerName.skoda]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: SKODA_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: SKODA_PROD_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: SKODA_PROD_GTM_ID,
      },
    },
  },
  [PartnerName.audi]: {
    TRACKING_ID: {
      [ProductName.VEHICLE]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: AUDI_PROD_GTM_ID,
      },
      [ProductName.TRAVEL]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: AUDI_PROD_GTM_ID,
      },
      [ProductName.CONTENT]: {
        [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_TEST]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_DEV]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_ACC]: ALL_BRANDS_TEST_GTM_ID,
        [EnvType.ENV_PROD]: AUDI_PROD_GTM_ID,
      },
    },
  },
}

// GTM Tracking per brand (partner)
const TRACKING_ID = GTM_CONFIG_MAP[PARTNER_NAME as PartnerName].TRACKING_ID

const INSTABANK_TEST_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
// MMG - Moller Mobile Group Skoda, Audi, Volkswagen
const MMG_TEST_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const INSTABANK_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const NORD_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const CIRCKEK_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const AUDI_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const SKODA_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'
const VOLKSWAGEN_PROD_INSURELY_CUSTOMER_ID = 'bf1dc1c1-1600-49c1-9651-59bfe1d53a32'

const INSURELY_CUSTOMER_ID_MAP: Record<PartnerName, Record<EnvType, string>> = {
  [PartnerName.instabank]: {
    [EnvType.ENV_LOCAL]: INSTABANK_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: INSTABANK_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: INSTABANK_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: INSTABANK_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: INSTABANK_PROD_INSURELY_CUSTOMER_ID,
  },
  [PartnerName.nord]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: NORD_PROD_INSURELY_CUSTOMER_ID,
  },
  [PartnerName.circlek]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: CIRCKEK_PROD_INSURELY_CUSTOMER_ID,
  },
  [PartnerName.volkswagen]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: VOLKSWAGEN_PROD_INSURELY_CUSTOMER_ID,
  },
  [PartnerName.skoda]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: SKODA_PROD_INSURELY_CUSTOMER_ID,
  },
  [PartnerName.audi]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CUSTOMER_ID,
    [EnvType.ENV_PROD]: AUDI_PROD_INSURELY_CUSTOMER_ID,
  },
}

// Insurely CustomerID per environment
const INSURELY_CUSTOMER_ID = INSURELY_CUSTOMER_ID_MAP[PARTNER_NAME as PartnerName][ENV]

const INSTABANK_TEST_INSURELY_CONFIG_NAME = 'no-digisure-switcher-test'
// MMG - Moller Mobile Group Skoda, Audi, Volkswagen
const MMG_TEST_INSURELY_CONFIG_NAME = 'no-digisure-switcher-test'
const ALL_BRANDS_TEST_INSURELY_CONFIG_NAME = 'no-digisure-switcher-test'
const INSTABANK_PROD_INSURELY_CONFIG_NAME = 'no-digisure-switcher'
const NORD_PROD_INSURELY_CONFIG_NAME = 'no-digisure-nord-switcher'
const CIRCKEK_PROD_INSURELY_CONFIG_NAME = 'se-digisure-switcher'
const AUDI_PROD_INSURELY_CONFIG_NAME = 'no-digisure-audi-swticher'
const SKODA_PROD_INSURELY_CONFIG_NAME = 'no-digisure-skoda-switcher'
const VOLKSWAGEN_PROD_INSURELY_CONFIG_NAME = 'no-digisure-volkswagen-switcher'

const INSURELY_CONFIG_NAME_MAP: Record<PartnerName, Record<EnvType, string>> = {
  [PartnerName.instabank]: {
    [EnvType.ENV_LOCAL]: INSTABANK_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: INSTABANK_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: INSTABANK_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: INSTABANK_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: INSTABANK_PROD_INSURELY_CONFIG_NAME,
  },
  [PartnerName.nord]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: NORD_PROD_INSURELY_CONFIG_NAME,
  },
  [PartnerName.circlek]: {
    [EnvType.ENV_LOCAL]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: ALL_BRANDS_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: CIRCKEK_PROD_INSURELY_CONFIG_NAME,
  },
  [PartnerName.volkswagen]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: VOLKSWAGEN_PROD_INSURELY_CONFIG_NAME,
  },
  [PartnerName.skoda]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: SKODA_PROD_INSURELY_CONFIG_NAME,
  },
  [PartnerName.audi]: {
    [EnvType.ENV_LOCAL]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_TEST]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_DEV]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_ACC]: MMG_TEST_INSURELY_CONFIG_NAME,
    [EnvType.ENV_PROD]: AUDI_PROD_INSURELY_CONFIG_NAME,
  },
}

// Insurely Config Name per environment
const INSURELY_CONFIG_NAME = INSURELY_CONFIG_NAME_MAP[PARTNER_NAME as PartnerName][ENV]

// Auth Portal host
const AUTH_PORTAL_LOCAL_HOST = 'https://auth.dev.dg-eleks.com'
const AUTH_PORTAL_DEV_HOST = 'https://auth.dev.dg-eleks.com'
const AUTH_PORTAL_ACC_HOST = 'https://auth.acc.dg-eleks.com'
const AUTH_PORTAL_PROD_HOST = 'https://auth.digisure.no'

const AUTH_PORTAL_HOST_MAP: Record<EnvType, string> = {
  [EnvType.ENV_LOCAL]: AUTH_PORTAL_LOCAL_HOST,
  [EnvType.ENV_TEST]: AUTH_PORTAL_LOCAL_HOST,
  [EnvType.ENV_DEV]: AUTH_PORTAL_DEV_HOST,
  [EnvType.ENV_ACC]: AUTH_PORTAL_ACC_HOST,
  [EnvType.ENV_PROD]: AUTH_PORTAL_PROD_HOST,
}

// Auth portal host per env
const AUTH_PORTAL_HOST = AUTH_PORTAL_HOST_MAP[ENV]

// link to agent portal
const AGENT_PORTAL_PROD_HOST = 'https://moller.digisure.no'
const AGENT_PORTAL_ACC_HOST = 'https://agent-portal.acc.dg-eleks.com'
const AGENT_PORTAL_DEV_HOST = 'https://agent-portal.dev.dg-eleks.com'
const AGENT_PORTAL_LOCAL_HOST = 'http://localhost:8081'

const AGENT_PORTAL_HOST_MAP: Record<EnvType, string> = {
  [EnvType.ENV_LOCAL]: AGENT_PORTAL_LOCAL_HOST,
  [EnvType.ENV_TEST]: AGENT_PORTAL_LOCAL_HOST,
  [EnvType.ENV_DEV]: AGENT_PORTAL_DEV_HOST,
  [EnvType.ENV_ACC]: AGENT_PORTAL_ACC_HOST,
  [EnvType.ENV_PROD]: AGENT_PORTAL_PROD_HOST,
}

//agent portal host per env
const AGENT_PORTAL_HOST = AGENT_PORTAL_HOST_MAP[ENV]

export enum LanguageCode {
  SV = 'sv',
  NO = 'no',
}

export enum CountyCode {
  SE = 'se',
  NO = 'no',
}

const LANGUAGE_CODE_MAP: Record<PartnerName, LanguageCode> = {
  [PartnerName.instabank]: LanguageCode.NO,
  [PartnerName.nord]: LanguageCode.NO,
  [PartnerName.circlek]: LanguageCode.SV,
  [PartnerName.volkswagen]: LanguageCode.NO,
  [PartnerName.skoda]: LanguageCode.NO,
  [PartnerName.audi]: LanguageCode.NO,
}

const CONFIG = {
  TRACKING_ID,
  INSURELY_CUSTOMER_ID,
  INSURELY_CONFIG_NAME,
  AUTH_PORTAL_HOST,
  AGENT_PORTAL_HOST,
  LANGUAGE_CODE: LANGUAGE_CODE_MAP[PARTNER_NAME as PartnerName],
  RECAPTCHA_SITE_KEY: '6LfAHAoaAAAAADh2gDgJ4yu1RKe3u0A8-WsB0zi3',
  USER_INFO_KEY: 'ds-user-data',
  USER_INFO_KEY_BID: 'ds-user-data-bid',
  APOLLO_STORAGE_KEY: 'ds-storage',
  PRODUCT_ID_KEY: 'PID',
  SERVICE: {
    avtalegiro: {
      NUMBER: '15063521903',
      TEST: {
        URL: 'https://pvu-test.nets.no',
      },
      PRODUCTION: {
        URL: 'https://pvu.nets.no',
      },
    },
  },
  DEV_TOOLS: [true, 'true'].includes(process.env.DEV_TOOLS),
}

export enum FlowName {
  QUOTE_AND_BUY = 'qb',
  MY_PAGES = 'mypages',
}

export default CONFIG

export const SESSION_ID_KEY = 'session-id'
