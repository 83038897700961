import {
  FLOW_NAME_QUERY_KEY,
  getFlowName,
  getProductName,
  PRODUCT_NAME_QUERY_KEY,
} from '@dg-util/productHandler'
import { removePersistedState } from '../FSM/util'
import apiV2 from '../service/apiV2.service'
import queryClient from '../queryClient'
import { SESSION_ID_KEY } from '../config'
import { clearContextCache, Context } from '@dg-shared/ContextProvider'
import { useCallback, useContext } from 'react'
import messagesQueue from '../service/messagesQueue'

export const safeSessionStorageClear = () => {
  const flowName = getFlowName()
  const productName = getProductName()
  const sessionId = sessionStorage.getItem(SESSION_ID_KEY)
  const messages = messagesQueue.messages

  sessionStorage.clear()
  sessionStorage.setItem(FLOW_NAME_QUERY_KEY, flowName)
  sessionStorage.setItem(PRODUCT_NAME_QUERY_KEY, productName)
  if (sessionId) {
    // Avoid setting string 'null' value
    sessionStorage.setItem(SESSION_ID_KEY, sessionId)
  }
  if (messages) {
    // Avoid messagesQueue cleanup
    messagesQueue.updateMessagesStorage(messages)
  }
}

export const fullStorageCleanup = async () => {
  await apiV2.clearCookies()
  removePersistedState()
  clearContextCache()
  safeSessionStorageClear()
  queryClient.clear()
}

// Full Storage Clear (Cleanup) hook
export const useFullStorageCleanup = () => {
  const { clearContext } = useContext(Context)

  return useCallback(async () => {
    await fullStorageCleanup()
    clearContext()
  }, [clearContext])
}
