import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core'

import { OfferItemProps } from '../../../types/interface/offer.interface'
import { CustomCardHeader, ProductWrapper, Typography } from '@dg-shared'
import { exampleCarImg } from 'BrandedImages'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'

const useOffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      transition: '0.2s',
      [theme.breakpoints.up('md')]: {
        maxWidth: '340px',
      },
      '&:hover': {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
    button: {
      display: 'flex',
      height: '100%',
      justifyContent: 'start',
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      height: '100%',
      '& h2': {
        marginBottom: theme.spacing(1),
      },
    },
    imageContainer: {
      marginBottom: theme.spacing(2),
    },
    image: {
      marginTop: theme.spacing(2),
      display: 'block',
    },
    row: {
      display: 'flex',
    },
    licensePlate: {
      marginLeft: theme.spacing(1),
    },
    seePrice: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(2),
    },
    vehicleCardHeaderContainerStyle: {
      backgroundColor: theme.brand.colors.textSecondary,
      '& > div': {
        marginTop: 0,
      },
      ...theme.brand.assets.layout?.offerItemVehicleCardHeaderContainerStyle,
    },
  })
)

const OfferItem: FC<OfferItemProps> = ({
  vehicle,
  translations,
  customClassName,
  handleClick,
  showImage = true,
}) => {
  const classes = useOffersStyles()
  const IMG_TITLE = 'Example Car'

  return (
    <Card elevation={3} className={`${classes.card} ${customClassName}`} data-testid={'offer-card'}>
      <CardActionArea onClick={handleClick} className={classes.button}>
        {translations.vehicleCardTitle ? (
          <CustomCardHeader
            titleTextType='h3'
            titleColor='clean'
            withPrice={false}
            title={translations.vehicleCardTitle}
            containerClass={classes.vehicleCardHeaderContainerStyle}
          />
        ) : null}
        {showImage ? (
          <ProductWrapper
            partners={[PartnerName.instabank, PartnerName.circlek]}
            products={[ProductName.VEHICLE]}
          >
            <section className={classes.imageContainer}>
              <CardMedia
                component='img'
                className={classes.image}
                alt={IMG_TITLE}
                image={exampleCarImg}
                title={IMG_TITLE}
              />
            </section>
          </ProductWrapper>
        ) : null}
        <CardContent className={classes.content}>
          <Typography type='h2'>
            {`${vehicle.ManufacturingYear} ${vehicle.CarMake} ${vehicle.CarModel}`}
          </Typography>

          <section className={classes.row}>
            <Typography>{translations.regNum}</Typography>
            <Typography modifiers={['bold']} className={classes.licensePlate}>
              {vehicle.MotorRegistrationNumber}
            </Typography>
          </section>
          {translations.seePrice ? (
            <Typography color={'textCardFooter'} className={classes.seePrice}>
              {translations.seePrice}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OfferItem
