import React, { FC, useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { Events } from '../../../FSM/shared/constants'
import { Typography, FSMBtnNext } from '@dg-shared'
import { useTranslate, TranslatedTextType } from '../../../hooks/shared'
import { ServiceSend } from '../../../types/interface/fsm.interface'
import { Context } from '@dg-shared/ContextProvider'
import { INSURELY_RESULTS, SELECTED_INSURELY_QUOTE } from '../../../constants/insurely-constants'

const useCTAStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        '& > button:not(:last-child)': {
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(5),
        padding: theme.spacing(2),
        '& > button:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      },
    },
    button: {
      ...theme.brand.assets.layout?.ctaOverride,
      [theme.breakpoints.up('md')]: {
        width: '325px',
      },
    },
    customButton: {
      ...theme.brand.assets.layout?.btnNext,
      ...theme.brand.assets.layout?.compareBtn,
    },
    actionButtonsDesc: {
      textAlign: 'center',
    },
    ctaAnnotation: {
      marginBottom: theme.spacing(3),
      color: theme.brand.colors.main,
      ...theme.brand.assets.layout?.ctaAnnotation,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    ctaAnnotationDesc: {
      margin: 0,
    },
    errorMessageStyle: {
      textAlign: 'center',
    },
  })
)

interface CTAProps {
  productName: ProductName
  showCompareBtn: boolean
  send: ServiceSend
  isVerify: boolean
  errorInsurely: TranslatedTextType | null
}

const CTA: FC<CTAProps> = ({ productName, showCompareBtn, send, isVerify, errorInsurely }) => {
  const { t } = useTranslate(T.OFFER_REVIEW)
  const { setContext } = useContext(Context)
  const classes = useCTAStyles()
  const isVehicleProduct = productName === ProductName.VEHICLE

  // Compare button should be always visible on DEV and ACC environments for vehicle product
  // For PROD env - only in case of vehicle product and showCompareBtn = true
  const isCompareButtonVisible = isVehicleProduct && showCompareBtn
  const compareHandler = () => {
    // Cleanup previous Insurely compare data before new compare
    setContext({
      [INSURELY_RESULTS]: null,
      [SELECTED_INSURELY_QUOTE]: null,
    })
    send(Events.START_COMPARE)
  }

  const outlinedNextButton = PARTNER_NAME === PartnerName.skoda ? false : true

  return (
    <>
      <section className={classes.actionButtons}>
        {isCompareButtonVisible && (
          <FSMBtnNext
            handleClick={compareHandler}
            buttonText={t(`button.compare`)}
            outlined={outlinedNextButton}
            id='ctaBtnNext'
            customClassName={`${classes.button} ${classes.customButton}`}
            testId='compare-button'
          />
        )}
        <FSMBtnNext
          handleClick={() => send({ type: Events.NEXT, isVerify: isVerify })}
          buttonText={t(`goToPurchase`)}
          customClassName={classes.button}
          testId='goto-purchase-button'
        />
      </section>

      <section data-testid='review-cta-actionButtonDesc' className={classes.actionButtonsDesc}>
        {isCompareButtonVisible && (
          <Typography testId='review-cta-annotation' className={classes.ctaAnnotation}>
            {t('cta.title')}
          </Typography>
        )}
        {isVehicleProduct && !showCompareBtn && !errorInsurely && (
          <Typography className={classes.ctaAnnotation} testId='review-cta-annotation'>
            {t('cta.noCompareBtn')}
          </Typography>
        )}
        {/* //TODO: Stopped */}
        {!!errorInsurely && (
          <Typography
            testId='review-cta-annotationError'
            className={classes.errorMessageStyle}
            color='textError'
          >
            {errorInsurely}
          </Typography>
        )}
        <Typography
          testId='review-cta-annotationDesc'
          color='textSecondary'
          size='small'
          className={classes.ctaAnnotationDesc}
        >
          {t('cta.desc')}
        </Typography>
      </section>
    </>
  )
}

export default CTA
