import React, { FC, useEffect, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { format, add } from 'date-fns'
import { get } from 'lodash'

import { CTA_TYPE_TIMELINE } from '../../../constants/cta-constants'
import { FNOL_COLLISION_QUESTIONS, FORMAT_DAY_MONTH } from '../../../constants'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events, FSMCommonTransitions } from '../../../FSM/shared/constants'
import TimelineItem from '../../../components/Claims/TimelineItem'
import { logoMax } from 'BrandedImages'
import { Typography, withHidden, LMBasicV3FSM, LDCardTwoCol, CTA, FSMBtnNext } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { FNOL_COLLISION_ANSWERS_MAP } from '../../../constants/fnol-constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      height: '895px !important',
      '@media (max-height: 895px)': {
        height: '80vh !important',
      },
    },
    mobileTimeline: {
      margin: '0 15px',
      marginTop: '30px',
    },
    submitBtn: {
      marginBottom: theme.spacing(3),
    },
    timelineSection: {
      position: 'relative',
    },
    timelineList: {
      marginLeft: theme.spacing(3),
      position: 'relative',
      listStyleType: 'none',
    },
    border: {
      borderLeft: `2px dashed ${theme.palette.secondary.dark}`,
      height: '80%',
      position: 'absolute',
      top: '5px',
      left: '23px',
    },
    cardHeader: {
      marginBottom: theme.spacing(4),
      marginTop: `-${theme.spacing(1)}px`,
    },
  })
)

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const PageTimeline: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.FNOL_TIMELINE)
  const [claimInitDate, setClaimInitDate] = useState<Date>(new Date())
  const [flowName, setFlowName] = useState<string>('')
  const classes = useStyles()
  const { claimAnswers } = useClaim(service)

  useEffect(() => {
    // TODO: Get Date from Cached Claim State
    setClaimInitDate(new Date())
    getFlowName()
    // eslint-disable-next-line
  }, [])

  const getFlowName = () => {
    if (
      get(claimAnswers, [FNOL_COLLISION_QUESTIONS.WHAT_HAPPENED]) ==
      String(FNOL_COLLISION_ANSWERS_MAP.howMany)
    ) {
      return setFlowName(FSMCommonTransitions.FNOL_COLLISION_FLOW)
    }
    setFlowName('default')
  }

  const footer = (
    <section>
      <FSMBtnNext
        customClassName={classes.submitBtn}
        buttonText={t(`finished`)}
        handleClick={() => service.send(Events.NEXT)}
      />
      <CTA context={CTA_TYPE_TIMELINE} />
    </section>
  )

  const events = [
    {
      subHeader: format(add(claimInitDate, { days: 0 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 3 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 10 }), FORMAT_DAY_MONTH),
    },
    {
      subHeader: format(add(claimInitDate, { days: 15 }), FORMAT_DAY_MONTH),
    },
  ]

  const eventsList =
    !!flowName &&
    events.map((event: { subHeader: string }, idx) => {
      const subHeaderEstimated = `${event.subHeader} ${t('estimated')}`
      const subHeader = idx > 0 ? subHeaderEstimated : event.subHeader

      return (
        <TimelineItem
          key={idx}
          active={idx === 0}
          subHeaderTxt={subHeader}
          contentTxt={t(`step.${flowName}.text`)[idx]}
          headerTxt={t(`step.${flowName}.header`)[idx]}
        />
      )
    })

  const timeLine = (
    <section>
      <section className={classes.timelineSection}>
        <div className={classes.border} />
        <ul className={classes.timelineList}>{eventsList}</ul>
      </section>
      {footer}
    </section>
  )

  return (
    <>
      <MobileScreen
        breadcrumbs
        breadcrumbsBtnText={t(`back`)}
        send={service.send}
        titleHeader={t('title')}
        paragraphSubTitle={t('subtitle')}
      >
        {timeLine}
      </MobileScreen>
      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='fnolTimeline'
        brandPageBackgroundCard='fnolTimelineCard'
        cardClassName={classes.cardClass}
      >
        <Typography type='h1' className={classes.cardHeader}>
          {t('title')}
        </Typography>
        {timeLine}
      </DesktopScreen>
    </>
  )
}

export default PageTimeline
