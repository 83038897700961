import React, { FC } from 'react'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AUTH_CHOICE } from '../../../constants'
import CustomSwitch from '../../../components/shared/layouts/CustomSwitch'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CustomClassName } from '../../../types/interface/styles.interface'
import { getDataComponentName } from '@dg-util'

export const useAuthStartegyText = makeStyles(
  (theme: Theme) =>
    createStyles({
      paragraph: {
        marginBottom: theme.spacing(1),
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      titleWrapper: {
        display: 'flex',
      },
      title: {
        lineHeight: '1.2 !important',
        fontSize: '28px !important',
        ...theme.brand.typography.overrides?.authTitle,
        marginBottom: theme.spacing(2),
        maxWidth: '325px',
      },
      subtitle: {
        marginBottom: theme.spacing(1),
        ...theme.brand.typography.overrides?.authSubtitle,
      },
    }),
  { index: 1 }
)

const AuthStrategyTitle: FC<CustomClassName> = ({}) => {
  const classes = useAuthStartegyText({
    height: window.innerHeight,
  })
  const { t } = useTranslate(T.AUTH)

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <div className={classes.titleWrapper}>
        <Typography type='h1' className={classes.title}>
          {t('title')}
        </Typography>
      </div>
      <CustomSwitch showOn={[AUTH_CHOICE]}>
        <Typography color='headingSecondary' type='h3' className={classes.subtitle}>
          {t('subtitle')}
        </Typography>
      </CustomSwitch>
    </div>
  )
}

export default AuthStrategyTitle
