import { BaseActionObject, MachineConfig, StateNode } from 'xstate'
import { has, merge } from 'lodash'
import { Events, FSMCommonTransitions as TV } from './constants'
import { IS_PRODUCTION } from '../../config'
import { ERROR_QUOTE_CONSTRAINTS, ERROR_QUOTE_VALIDATION, ErrorCode } from '../../constants'
import { isCriticalError } from '@dg-util'

export type ContextType = { [key: string]: unknown }
export type StateSchemaType = {
  states: { [key: string]: StateNode }
}
export type EventType = { type: Events; isVerify: boolean }
export type MachineConfigType = MachineConfig<ContextType, StateSchemaType, EventType>

// FSM states that available only on DEV, LOCAL, ACC environments
const FSMStandards: MachineConfigType = !IS_PRODUCTION
  ? {
      states: {
        [TV.STANDARDS]: {
          on: {
            [Events.BACK]: TV.AUTH,
          },
        },
        [TV.PLAYGROUND]: {
          on: {
            [Events.BACK]: TV.AUTH,
          },
        },
      },
      on: {
        [Events.STANDARDS]: {
          target: TV.STANDARDS,
          actions: 'setBackPath',
        },
        [Events.PLAYGROUND]: {
          target: TV.PLAYGROUND,
          actions: 'setBackPath',
        },
      },
    }
  : {}

const FSMConfigCommon: MachineConfigType = {
  states: {
    [TV.ERROR]: {
      on: {
        [Events.BACK]: [
          // General shared critical error back navigation handling
          {
            target: TV.AUTH,
            actions: ['signOut'],
            cond: (ctx, type, state) => {
              const errorType = (state.state.event as BaseActionObject)?.errorType

              const isCriticalErrorType = [
                ERROR_QUOTE_CONSTRAINTS,
                ERROR_QUOTE_VALIDATION,
                ErrorCode.ERROR_CODE_NO_QUOTE,
              ].includes(errorType)
              const isCriticalErrorCode = isCriticalError(errorType?.code)

              return isCriticalErrorType || isCriticalErrorCode
            },
          },
          {
            target: TV.AUTH,
            actions: ['signOut'],
            cond: (ctx) => has(ctx.backPath, TV.AUTH),
          },
          {
            target: TV.QB_CHANGE,
            cond: (ctx) => ctx.backPath === TV.QB_CHANGE,
          },
          {
            target: TV.QB_VERIFY,
            cond: (ctx) => ctx.backPath === TV.QB_VERIFY,
          },
          {
            target: TV.QB_PURCHASED,
            cond: (ctx) => ctx.backPath === TV.QB_PURCHASED,
          },
        ],
      },
    },
  },
  on: {
    [Events.ERROR]: {
      target: TV.ERROR,
      actions: 'setBackPath',
    },
    [Events.AUTH_ERROR]: {
      target: TV.AUTH,
      actions: 'signOut',
    },
    [Events.AUTH]: {
      target: TV.AUTH,
      actions: 'signOut',
    },
    [Events.SESSION_EXPIRED]: {
      target: TV.AUTH,
      actions: 'signOut',
    },
  },
}

export default merge(FSMStandards, FSMConfigCommon)
