import { Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useReactQueryQuote from '../../hooks/shared/useReactQueryQuote'
import InfoIcon from './InfoIcon/InfoIcon'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      height: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    switch: {
      textDecoration: 'underline',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      color: theme.brand.colors.headingMain,
      ...theme.brand.typography.text.normal,
      [theme.breakpoints.down('md')]: {
        fontSize: 11,
      },
    },
    text: {
      margin: 0,
      ...theme.brand.typography.overrides?.contactCustomerSwitchText,
      [theme.breakpoints.down('md')]: {
        fontSize: 11,
      },
    },
  })
)

export type ContactCustomerSwitchPropsType = {
  wrapperClass?: string
  testId?: string
}

const ContactCustomerSwitch: FC<ContactCustomerSwitchPropsType> = ({ wrapperClass, testId }) => {
  const classes = useStyles()
  const { t } = useTranslate()
  const { updateQuote, quote, isLoading } = useReactQueryQuote()
  const { ContactAllowed = true } = quote
  const contactAllowedString = ContactAllowed.toString() as 'true' | 'false'
  const switchLabelMap = {
    true: 'turnOff',
    false: 'turnOn',
  }
  const switchLabel = switchLabelMap[contactAllowedString]
  const partnerName = getLocalizedPartnerName()

  const switchHandler = async () => {
    if (ContactAllowed) {
      await updateQuote({ ContactAllowed: false })
    } else {
      await updateQuote({ ContactAllowed: true })
    }
  }

  const classesWrapper = [classes.wrapper, wrapperClass].filter((item) => item).join(' ')
  const content = isLoading ? (
    <Typography component='span'>{t(`loadTitleDefault`)}</Typography>
  ) : (
    <Typography component='span' className={classes.text}>
      {t(`contactCustomerSwitch.text`)}
      <button className={classes.switch} onClick={switchHandler}>
        {t(`contactCustomerSwitch.${switchLabel}`)}
      </button>
    </Typography>
  )

  return (
    <div id={testId} className={classesWrapper}>
      <InfoIcon>
        <Typography>{t(`infoIconContactCustomerSwitch.text`, { partnerName })}</Typography>
      </InfoIcon>
      {content}
    </div>
  )
}

export default ContactCustomerSwitch
