import React, { FC } from 'react'
import { Card } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import useLogic from '../../../pages/qb/Review/useLogic'
import { CustomCardHeader, LDContainerV2FSM, LMBasicV3FSM, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import Loading from '../../../pages/qb/Loading/Page'
import CTA from '../../../pages/qb/Review/CTA'
import ContentVehicle from '../../../pages/qb/Review/Content/Vehicle'
import PageWrapper from '@dg-shared/PageWrapper'
import useOfferReviewPageStyles from '../../../pages/qb/Review/useOfferReviewPageStyles'
import { getDataComponentName } from '@dg-util'

const ReviewPage: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.OFFER_REVIEW)
  const classes = useOfferReviewPageStyles()
  const { loading, productName, quote, getBonusText, errorInsurely, btnBackText } =
    useLogic(service)

  if (loading || !quote) {
    return <Loading />
  }

  const priceDetails = quote.CalculatedPrices.taxAnnual || quote.CalculatedPrices.priceAnnual

  const content = quote && (
    <div data-componentname={getDataComponentName(__filename)}>
      <Card data-testid='review-content-card' elevation={3} className={classes.backgroundImage}>
        <CustomCardHeader
          title={t(`offer`)}
          priceMonthly={quote.CalculatedPrices.priceMonthly}
          priceMonthlyText={t(`card.priceMonthly`)}
          priceDetailsText={t(`card.priceDetails`, { value: priceDetails })}
        />
        <ContentVehicle
          currentState={currentState}
          send={service.send}
          quote={quote}
          getBonusText={getBonusText}
        />
      </Card>
      <CTA
        productName={productName}
        send={service.send}
        isVerify={quote.canVerify}
        errorInsurely={(!!errorInsurely && t(`error.${errorInsurely}`)) || null}
        //TODO: Change the type of the quote --> after we will have any possibility to check
        // for different product types. Different properties are needed to do this check
        showCompareBtn={!errorInsurely && quote.canComparePrice}
      />
    </div>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(`${btnBackText}`)}
      breadcrumbsStepPercentage={50}
    >
      <Typography type='h1' className={classes.title}>
        {t('title')}
      </Typography>
      <Typography testId='review-subtitle' className={classes.subTitle}>
        {t('subtitle')}
      </Typography>
      {content}
    </PageWrapper>
  )
}
export default ReviewPage
