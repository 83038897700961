import { get } from 'lodash'
import React, { FC, useState } from 'react'
import { CardContent, CardMedia, Divider, Hidden } from '@material-ui/core'
import { getCoverageText } from '@dg-util/getCoverageData'

import { VEHICLE_COVERAGE_TYPE_MAP } from '../../../../constants'
import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import {
  CoverageTypeKeyType,
  PartnerName,
  ProductName,
} from '../../../../types/interface/quote.interface'
import { Events } from '../../../../FSM/shared/constants'
import { formatWithThousandSpace } from '../../../../service/util.service'
import { CoverageDetailedInfo, ProductWrapper, Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import BtnEdit from '@dg-shared/BtnEdit'
import useContentStyles from '../../../../hooks/shared/useContentStyles'
import { exampleCarImg } from 'BrandedImages'
import CoverageInfoIcon from '@dg-shared/InfoIcon/CoverageInfoIcon'
import DetailedInfoButtonShared from '@dg-shared/DetailedInfoButtonShared'
import { FSMStateType, ServiceSend } from '../../../../types/interface/fsm.interface'
import { QuoteV2Type } from '../../../../types/ApiV2'

interface ContentVehicle {
  send?: ServiceSend
  currentState?: FSMStateType
  quote: Partial<QuoteV2Type>
  getBonusText(value: string): string
}

const ContentVehicle: FC<ContentVehicle> = ({ send, quote, getBonusText, currentState }) => {
  const classes = useContentStyles({ isImage: true })
  const { t } = useTranslate(T.OFFER_REVIEW)
  const [coverageInfoOpened, setCoverageInfoState] = useState<boolean>(false)
  const deductible = quote.CarComprehensiveDeductible
  const mileageAnnual = quote.AnnualMileage
  const carPackage = quote.CarPackage
  const coverageName = get(VEHICLE_COVERAGE_TYPE_MAP, [PARTNER_NAME, carPackage], null)

  const coverage = (
    <>
      <Hidden mdUp>
        <DetailedInfoButtonShared
          showDetails={() => setCoverageInfoState(true)}
          color={PartnerName.skoda ? 'textSecondary' : 'main'}
        >
          <Typography testId='review-coverage-extent-value' className={classes.rightColItem}>
            {coverageName}
          </Typography>
        </DetailedInfoButtonShared>
      </Hidden>
      <Hidden smDown>
        <Typography testId='review-coverage-extent-value' className={classes.rightColItem}>
          {coverageName}
        </Typography>
      </Hidden>
    </>
  )

  const coverageInfo = (
    <CoverageDetailedInfo
      state={currentState}
      send={send}
      coverageType={carPackage}
      onClose={() => setCoverageInfoState(false)}
      translationCondition={quote.SuperCoverage ? 'isSuper' : 'isNotSuper'}
    />
  )

  return (
    <CardContent className={classes.twoCol}>
      <section className={classes.colLeft} data-testid={'quote-info-block'}>
        {/* //NOTE: Need block container for Safari when use forms */}
        <section>
          <ProductWrapper
            partners={[PartnerName.circlek, PartnerName.instabank]}
            products={[ProductName.VEHICLE]}
          >
            <CardMedia
              component='img'
              alt='Example Car'
              image={exampleCarImg}
              className={classes.itemImage}
            />
          </ProductWrapper>
        </section>
        <Typography type='h2' className={classes.cardTitle} testId={'vehicle-info'}>
          {[quote.ManufacuringYear, quote.CarMake, quote.CarModel].filter((item) => item).join(' ')}
        </Typography>
        <Typography type='h3' className={classes.cardSubTitle} testId={'regNum-info'}>
          {t(`registrationNum`, { regNum: quote.MotorRegistrationNumber })}
        </Typography>
        <Divider className={classes.divider} />
        <>
          <div className={classes.column} data-testid={'coverage-data'}>
            <div className={classes.row}>
              <Typography testId='review-coverage-extent'>{t(`coverage`)}</Typography>
              {coverage}
              {coverageInfoOpened && coverageInfo}
            </div>
            <ProductWrapper partners={[PartnerName.circlek]} products={Object.values(ProductName)}>
              {[CoverageTypeKeyType.halv, CoverageTypeKeyType.hel].includes(
                carPackage as CoverageTypeKeyType
              ) && (
                <div className={classes.row}>
                  <Typography testId='review-coverage-super'>
                    {t(`coverageDetails.super.title`)}
                  </Typography>
                  <Typography className={classes.rightColItem} color='textError'>
                    <CoverageInfoIcon
                      coverageType={getCoverageText(CoverageTypeKeyType.super)}
                      color='main'
                      label={t(quote.SuperCoverage ? `yes` : `no`)}
                    />
                  </Typography>
                </div>
              )}
            </ProductWrapper>
            {!!deductible && (
              <div className={classes.row}>
                <Typography testId='review-coverage-deductible'>{t(`deductible.title`)}</Typography>
                <Typography
                  testId='review-coverage-deductible-value'
                  className={classes.rightColItem}
                >
                  {t(`deductible.value`, {
                    deductible: formatWithThousandSpace(deductible),
                  })}
                </Typography>
              </div>
            )}
            <ProductWrapper
              partners={[
                PartnerName.instabank,
                PartnerName.volkswagen,
                PartnerName.skoda,
                PartnerName.audi,
                PartnerName.nord,
              ]}
              products={Object.values(ProductName)}
            >
              <div className={classes.row}>
                <Typography testId='review-coverage-bonus'>{t(`bonus`)}</Typography>
                <Typography testId='review-coverage-bonus-value' className={classes.rightColItem}>
                  {getBonusText(quote.CarBonus)}
                </Typography>
              </div>
            </ProductWrapper>
            <div className={classes.row}>
              <Typography testId='review-coverage-drivingDistance'>
                {t(`yearlyDrivingDistance.title`)}
              </Typography>
              <Typography
                testId='review-coverage-drivingDistance-value'
                className={classes.rightColItem}
              >
                {t(`yearlyDrivingDistance.value`, {
                  annualMileage: formatWithThousandSpace(mileageAnnual),
                })}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography testId='review-coverage-driversAge'>{t(`prevDriversAge`)}</Typography>
              <Typography
                testId='review-coverage-driversAge-value'
                className={classes.rightColItem}
              >
                {quote.YoungDrivers ? t(`yes`) : t(`no`)}
              </Typography>
            </div>
          </div>
          <BtnEdit
            id='change-offer-button'
            testId='change-offer-button'
            handleClick={() => send(Events.CHANGE)}
            text={t(`button.changeOffer`)}
          />
        </>
      </section>
      <Hidden smDown>
        <div className={classes.colRight}>{coverageInfo}</div>
      </Hidden>
    </CardContent>
  )
}

export default ContentVehicle
