import React, { FC } from 'react'

import { CTA_CONTEXT } from '../../constants/cta-constants'
import { useCtaStyles } from '../../styles/common/cta'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import BrandConfig from 'BrandConfig'

interface CTAProps {
  containerClass?: string
  context: string
}

const CTA: FC<CTAProps> = ({ context, containerClass }) => {
  const ctaClasses = useCtaStyles({ externalLink: true })
  const { t } = useTranslate()

  return (
    <footer className={`${ctaClasses.footer} ${containerClass}`}>
      <Typography color='textSecondary' className={ctaClasses.disclaimer}>
        {t(CTA_CONTEXT[context].ctaText)}
      </Typography>
      {BrandConfig.SUPPORT_LINK ? (
        <a
          href={CTA_CONTEXT[context].linkPath}
          target={(CTA_CONTEXT[context]?.blank && '_blank') || '_self'}
          className={ctaClasses.link}
        >
          {t(CTA_CONTEXT[context].linkText)}
        </a>
      ) : (
        <a
          href={`mailto:${BrandConfig.EMAIL}`}
          className={ctaClasses.link}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {t(CTA_CONTEXT[context].linkText, { email: BrandConfig.EMAIL })}
        </a>
      )}
    </footer>
  )
}

export default CTA
