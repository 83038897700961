import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { TranslatedTextType } from '../../hooks/shared'

interface PageTitleProps {
  className?: string
  text: TranslatedTextType
}

const PageTitle: FC<PageTitleProps> = ({ className, text }) => {
  return (
    <Typography className={className} variant='h1' color='primary' gutterBottom>
      {text}
    </Typography>
  )
}

export default PageTitle
