import React, { FC } from 'react'
import { isEmpty, isNil, isUndefined } from 'lodash'
import { FieldRenderProps } from 'react-final-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { MenuItem, Select, FormControl, FormHelperText, InputLabel } from '@material-ui/core'

import { IconType } from '../../../types/interface/icon.interface'
import { BrandIconShared } from '@dg-shared'
import { TranslatedTextType } from '../../../types'
import { DatasourceValue } from '../../../types/interface/datasource.interface'
import useReactQueryDatasource from '../../../hooks/shared/useReactQueryDatasource'
import { InsuranceCompanyV2Type } from '../../../types/ApiV2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      position: 'static',
      marginBottom: theme.spacing(3),
    },
    inputLabel: {
      position: 'relative',
      color: theme.palette.info.dark,
      fontSize: theme.spacing(2),
      lineHeight: 1.4, // 22
    },
    select: {
      marginTop: theme.spacing(2),
      color: theme.palette.primary.dark,
    },
    placeholderStyle: {
      color: theme.palette.info.dark,
      fontStyle: 'italic',
    },
    placeholderListItem: {
      fontStyle: 'italic',
    },
    icon: {
      pointerEvents: 'none',
      position: 'absolute',
      right: theme.spacing(3),
      ...theme.brand.assets.layout?.iconCustomColor,
    },
  })
)

interface CompanySelectProps extends FieldRenderProps<unknown> {
  itemText?: TranslatedTextType
  defaultItemText: string
  isInsured: boolean
  placeholder: string
  companies?: Array<DatasourceValue>
}

const CompanySelect: FC<CompanySelectProps> = ({ input, meta, fieldLabel, labelId, ...props }) => {
  const classes = useStyles()
  const { insuranceCompanies } = useReactQueryDatasource()

  const items =
    insuranceCompanies &&
    insuranceCompanies.map((company: InsuranceCompanyV2Type, idx: number) => {
      return (
        <MenuItem key={`${company.PartyId}-${idx}`} value={company.PartyId}>
          {company.OrganizationName}
        </MenuItem>
      )
    })

  const selectLabel = (
    <InputLabel id={labelId} className={classes.inputLabel} shrink>
      {fieldLabel}
    </InputLabel>
  )

  return (
    <FormControl className={classes.formControl}>
      {selectLabel}
      <Select
        displayEmpty
        IconComponent={() => (
          <BrandIconShared customClass={classes.icon} type={IconType.ARROW_DOWN} />
        )}
        variant='outlined'
        value={isUndefined(input.value) ? '' : input.value}
        onChange={input.onChange}
        className={classes.select}
        classes={{
          root: isNil(input.value) || isEmpty(String(input.value)) ? classes.placeholderStyle : '',
        }}
        labelId={labelId}
      >
        {!props.isInsured && (
          <MenuItem value='' disabled className={classes.placeholderListItem}>
            {props.fieldPlaceholder}
          </MenuItem>
        )}
        {!!props.defaultItemText && <MenuItem value={0}>{props.defaultItemText}</MenuItem>}
        {items}
      </Select>
      <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
  )
}

export default CompanySelect
