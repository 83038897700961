import { getQueryParamByName } from '@dg-util/getQueryParam'
import { CONTEXT_CACHE_KEY } from '@dg-shared/ContextProvider'
import { GDPRDataType } from '@dg-shared/AcceptCookies'
import { PolicyV2Type, ProductPropV2Type, ResponseErrorV2Type, VehicleV2Type } from '../types/ApiV2'
import format from 'date-fns/format'
import {
  ERROR_TYPE,
  ErrorCode,
  ErrorStatus,
  FORMAT_DAY_MONTH_YEAR_DOT,
  localizedPartnerName,
} from '../constants'
import { Events, PolicyStatus } from '../FSM/shared/constants'
import { FlowServiceType } from '../types/interface/fsm.interface'
import { PartnerName, ProductName } from '../types/interface/quote.interface'
import { getProductName } from '@dg-util/productHandler'

export { default as getCookieByName } from './getCookieByName'
export { getQueryParam, getQueryParamByName } from './getQueryParam'
export { default as normalizeFormat } from './normalizeFormat'
export { default as setPageError } from './setPageError'
export { default as urlIdentifier } from './urlIdentifier'
export { default as defineOfferDate } from './defineOfferDate'
export { default as formatPolicyDate } from './formatPolicyDate'
export { default as getCoverageData } from './getCoverageData'
export { default as setCookie } from './setCookie'
export { default as normalizeMotorRegistrationNumber } from './normalizeMotorRegistrationNumber'
export { default as getBackButtonText } from './getBackButtonText'
export { filterVehicleInsurelyResults } from './insurely'
export { getProductName, getFlowName } from './productHandler'
export {
  setSessionStorageAuthItem,
  setSessionAuthMethod,
  getSessionAuthMethod,
} from './sessionStorage'
export { default as redirect } from './redirectExternal'
export { safeSessionStorageClear, fullStorageCleanup, useFullStorageCleanup } from './storageClear'
export { default as addSpaceRegNum } from './addSpaceRegNum'
export { default as updateDateWithTimeISO } from './updateDateWithTimeISO'
export { base64ToArrayBuffer, saveByteArray } from './fileDownload'

export const getSearchParams = () =>
  new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as unknown as Record<string, string>

export const DATA_LAYER_UTM_CACHE_KEY = 'DATA_LAYER_UTM_CACHE_KEY'

export const getGDPRSettings = (): GDPRDataType => {
  const contextCache = JSON.parse(localStorage.getItem(CONTEXT_CACHE_KEY))
  return contextCache?.acceptCookies || {}
}

export type DataLayerSettingsType = {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_id: string
  utm_term: string
  utm_content: string
  gdpr_setting: GDPRDataType
}

export const getDataLayerSettings = (): DataLayerSettingsType => {
  // Check if UTM is passed through the url
  let dataLayerUTMData = {
    utm_source: getQueryParamByName('utm_source'),
    utm_medium: getQueryParamByName('utm_medium'),
    utm_campaign: getQueryParamByName('utm_campaign'),
    utm_id: getQueryParamByName('utm_id'),
    utm_term: getQueryParamByName('utm_term'),
    utm_content: getQueryParamByName('utm_content'),
  }

  // Preserve DATA_LAYER_UTM for future usage if present
  if (dataLayerUTMData.utm_source || dataLayerUTMData.utm_id) {
    localStorage.setItem(DATA_LAYER_UTM_CACHE_KEY, JSON.stringify(dataLayerUTMData))
  } else {
    // Get Preserved DATA_LAYER_UTM if available
    dataLayerUTMData = JSON.parse(localStorage.getItem(DATA_LAYER_UTM_CACHE_KEY)) || {}
  }

  const gdpr_setting = getGDPRSettings()

  return {
    ...dataLayerUTMData,
    gdpr_setting,
  }
}

const SELLING_CHANNEL_QUERY_PARAM = 'source'
export const SELLING_CHANNEL_CACHE_KEY = 'SELLING_CHANNEL_CACHE_KEY'

export const getSellingChannel = () => {
  let sellingChannel = getQueryParamByName(SELLING_CHANNEL_QUERY_PARAM)

  if (sellingChannel) {
    // Set selling channel in cache
    localStorage.setItem(SELLING_CHANNEL_CACHE_KEY, sellingChannel)
  } else {
    // Get Preserved sellingChannel if available
    sellingChannel = localStorage.getItem(SELLING_CHANNEL_CACHE_KEY)
  }

  return sellingChannel
}

export const clearSellingChannel = () => localStorage.removeItem(SELLING_CHANNEL_CACHE_KEY)

export type QueryParamsDataType = {
  dataLayerSettings: DataLayerSettingsType
  sellingChannel: string
  productName: ProductName
}

export const getQueryParamsData = (): QueryParamsDataType => {
  return {
    dataLayerSettings: getDataLayerSettings(),
    sellingChannel: getSellingChannel(),
    productName: getProductName(),
  }
}

export const getDataComponentName = (filename: string) =>
  filename
    .substr(filename.indexOf('src') + 4, filename.length)
    .replace('.tsx', '')
    .replace('.', '/')

export const getPolicyDates = (policy: PolicyV2Type) => {
  return {
    dateFrom:
      !!policy.InsuredFrom && format(new Date(policy.InsuredFrom), FORMAT_DAY_MONTH_YEAR_DOT),
    dateTo: !!policy.InsuredTo && format(new Date(policy.InsuredTo), FORMAT_DAY_MONTH_YEAR_DOT),
  }
}

export const getProductPropValue = (
  productProp: ProductPropV2Type,
  productPropValueKey: string | number
) => {
  const productPropItem = productProp.values.find((item) => item[productPropValueKey])
  return productPropItem?.[productPropValueKey]
}

export const filterPersonVehiclesByPartner = (
  currentPartner: PartnerName,
  vehicles: VehicleV2Type[]
): VehicleV2Type[] => {
  // All brands for Instabank, Nord
  if ([PartnerName.instabank, PartnerName.nord].includes(currentPartner)) {
    return vehicles
  }

  // Filter by specific partner
  return vehicles.filter((item) => PARTNER_NAME === item.CarMake.toLowerCase())
}

export const filterVehiclesWithoutPolicies = (
  vehicles: VehicleV2Type[],
  policies: Partial<PolicyV2Type>[]
) => {
  const policiesMotorRegistrationNumbers = policies.map((policy) => policy.MotorRegistrationNumber)
  return vehicles.filter(
    (vehicle) => !policiesMotorRegistrationNumbers.includes(vehicle.MotorRegistrationNumber)
  )
}

export const reactQueryErrorHandler = (
  error: ResponseErrorV2Type,
  flowService: FlowServiceType,
  backState?: string
) => {
  const eventTypeByErrorStatusMap: Record<ErrorStatus, Events> = {
    [ErrorStatus.UNAUTHORIZED]: Events.AUTH_ERROR,
    [ErrorStatus.SESSION_EXPIRED]: Events.SESSION_EXPIRED,
    [ErrorStatus.PROXY_AUTH_REQUIRED]: Events.AUTH_ERROR,
    [ErrorStatus.LOGIN_TIMEOUT]: Events.SESSION_EXPIRED,
  }

  const eventTypeByErrorCodeMap: Record<ErrorCode, Events> = {
    [ErrorCode.ERROR_CODE_QUOTE_VALIDATION]: Events.ERROR,
    [ErrorCode.ERROR_CODE_QUOTE_CONSTRAINTS]: Events.ERROR,
    [ErrorCode.ERROR_CODE_QUOTE_GENERAL]: Events.ERROR,
    [ErrorCode.ERROR_CODE_UNKNOWN_CHARACTERISTICS]: Events.WRONG_PRODUCT_ID,
    [ErrorCode.ERROR_CODE_AUTH_ACCOUNT_LOCKED]: Events.AUTH_ERROR,
    [ErrorCode.ERROR_CODE_NO_QUOTE]: Events.ERROR,
    [ErrorCode.ERROR_CODE_REG_NUM]: Events.WRONG_PRODUCT_ID,
    [ErrorCode.ERROR_CODE_SESSION_EXPIRED]: Events.SESSION_EXPIRED,
  }

  const errorCode: ErrorCode = error.response?.error?.code

  const eventTypeByErrorCode = eventTypeByErrorCodeMap[errorCode]
  const eventTypeByErrorStatus = eventTypeByErrorStatusMap[error.status] || Events.ERROR

  // Set Event Type based on Error Code or Error Status (according priorities)
  const errorEvent = eventTypeByErrorCode || eventTypeByErrorStatus || Events.ERROR

  flowService.send({
    type: errorEvent,
    errorType: ERROR_TYPE[errorCode],
    state: flowService.state.value,
    backState, // Event property helps to identify where to navigate back on an error page
  })
}

export const isCurrentActivePolicy = (policy: Partial<PolicyV2Type>) => {
  const currentDateTimestamp = new Date().getTime()
  const policyInsuredFromTimestamp = new Date(policy.InsuredFrom).getTime()
  const policyInsuredToTimestamp = new Date(policy.InsuredTo).getTime()

  return (
    policyInsuredFromTimestamp < currentDateTimestamp &&
    policyInsuredToTimestamp > currentDateTimestamp &&
    [PolicyStatus.ACCEPTED, PolicyStatus.VALID].includes(policy.PolicyStatus)
  )
}

// Check if the error is critical (requires sign-out and redirect to auth page)
export const isCriticalError = (code: ErrorCode): boolean =>
  [
    ErrorCode.ERROR_CODE_QUOTE_CONSTRAINTS,
    ErrorCode.ERROR_CODE_QUOTE_VALIDATION,
    ErrorCode.ERROR_CODE_NO_QUOTE,
  ].includes(code)

export const getLocalizedPartnerName = () => localizedPartnerName[PARTNER_NAME as PartnerName]
