import { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { useSelector } from '@xstate/react'
import { get } from 'lodash'

import CONFIG from '../../../config'
import { Events } from '../../../FSM/shared/constants'
import { SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER } from '../../../constants'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { normalizeMotorRegistrationNumber } from '@dg-util'
import BrandConfig from 'BrandConfig'
import { useFullStorageCleanup } from '@dg-util/storageClear'
import useReactQueryAuth from '../../../hooks/shared/useReactQueryAuth'
import { AuthRegularV2InputType } from '../../../types/ApiV2'
import apiV2, { isAuthTokenExist } from '../../../service/apiV2.service'
import { Context } from '@dg-shared/ContextProvider'
import { UserAuthInput } from '../../../types'
import messagesQueue from '../../../service/messagesQueue'

interface HookAuth {
  loading: boolean
  errorType: string
  errorVipps: boolean
  vippsLink: string
  authHandler(data: UserAuthInput): void
}

let isReCaptchaReady = false

const getReCaptchaToken = async (isReCaptchaReady: boolean, key: string) => {
  if (!isReCaptchaReady) return

  try {
    return (await window.grecaptcha.execute(key, { action: 'submit' })) as string
  } catch (error) {
    console.error('ReCaptcha execution error', error)
    return
  }
}

const useLogic = (service: FlowServiceType): HookAuth => {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorVipps, setErrorVipps] = useState<boolean>(false)
  const [vippsLink, setVippsLink] = useState<string>('')
  const stateEventType = useSelector(service, (state) => state.event.type)
  const [eventType, setEventType] = useState<string>(stateEventType)

  // Update eventType with current FSM state event type
  useEffect(() => {
    setEventType(stateEventType)

    // Check error events in messages queue
    const messageQueueAuthError = messagesQueue.getMessage(Events.AUTH_ERROR)
    const messageQueueSessionError = messagesQueue.getMessage(Events.SESSION_EXPIRED)

    if (messageQueueAuthError || messageQueueSessionError) {
      // Update eventType for correct error handling
      setEventType(messageQueueAuthError?.id || messageQueueSessionError?.id)
    }
  }, [stateEventType])

  const { VIPPS_AUTH } = BrandConfig
  const mounted = useRef(false)
  const cleanup = useFullStorageCleanup()
  const { authRegular, signOut, isAuthRegularLoading } = useReactQueryAuth()
  const { setContext } = useContext(Context)

  useEffect(() => {
    setLoading(isAuthRegularLoading)
  }, [isAuthRegularLoading])

  const authHandler = useCallback(
    async (userInput: AuthRegularV2InputType) => {
      setLoading(true)
      const reCaptchaToken = await getReCaptchaToken(isReCaptchaReady, CONFIG.RECAPTCHA_SITE_KEY)

      await authRegular({ ...userInput, reCaptchaToken })

      if (userInput.regNum) {
        setContext({
          [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: normalizeMotorRegistrationNumber(
            userInput.regNum
          ),
        })
      }

      setLoading(false)
      service.send(Events.NEXT)
    },
    [authRegular, service, setContext]
  )

  const getVipps = async () => {
    const { vLink, errVipps } = await apiV2.getVippsLink()
    const error = !!errVipps

    return error ? setErrorVipps(error) : setVippsLink(vLink)
  }

  const resetClient = useCallback(async () => {
    await cleanup()

    if (window.grecaptcha.ready) {
      isReCaptchaReady = true
    }
  }, [cleanup])

  const signOutHandler = async () => {
    const previousAction = get(service.state, ['transitions', '0', 'actions', '0'])

    if (get(previousAction, 'type') === 'signOut') {
      // SignOut in case auth token exists to avoid redundant auth error
      if (isAuthTokenExist()) {
        await signOut()
      }
    }
  }

  useEffect(() => {
    mounted.current = true

    // Sign-out and clear cache and cookies if it is not a successful Vipps login (custom-auth)
    // TODO: Ideally AuthStrategy page that use this hook should not be rendered in case of successful authentication
    // TODO: refactor FSM config to show authStartegy page and call this hook only in case of a fresh authentication
    // TODO: Maybe, some pages (like auth pages) should be out of the FSM with some default URL based routing
    const shouldClearCacheAndSignOut = service.state?.event.type !== Events.CUSTOM_AUTH_SUCCESS

    if (shouldClearCacheAndSignOut) {
      ;(async () => {
        await signOutHandler()

        resetClient()
          .then(async () => {
            // Use Vipps auth if it is enabled on a brand level
            if (VIPPS_AUTH) {
              await getVipps()
            }
          })
          .finally(() => {
            // This prevents of state update on unmounted component
            if (mounted.current) {
              setLoading(false)
            }
          })
      })()
    }

    return () => {
      setLoading(false)
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetClient])

  return {
    errorType: [Events.AGENT_AUTH, Events.BACK].includes(eventType as Events) ? null : eventType,
    loading,
    errorVipps,
    vippsLink,
    authHandler,
  }
}

export default useLogic
