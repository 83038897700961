import { FormValidationType } from '../../../types/interface/form.interface'
import { FORM_ID, FORMAT_DAY_MONTH_YEAR_DOT } from '../../../constants'
import Joi from '@hapi/joi'
import {
  REGEX_MILEAGE_FIELD,
  REGEX_MULTIPLE_DIGITS,
  REGEX_PRODUCT_ID,
  REGEX_SSN,
} from '../../../constants/regex-constants'
import { nameValidation } from '../../../validation/fieldsVS'
import format from 'date-fns/format'

const quoteAndBuyFormValidation = (brandConfig: Record<string, unknown>): FormValidationType => ({
  [FORM_ID.AUTH]: {
    vehicle: Joi.object({
      ssn: Joi.string().required().empty().regex(REGEX_SSN).min(11).max(11).messages({
        'string.base': 'auth.form.ssn.validation.base',
        'string.empty': 'auth.form.ssn.validation.empty',
        'string.pattern.base': 'auth.form.ssn.validation.base',
        'string.min': 'auth.form.ssn.validation.base',
        'string.max': 'auth.form.ssn.validation.base',
        'any.required': '',
      }),
      firstName: nameValidation('firstName', 'auth.form.firstName.validation.base'),
      lastName: nameValidation('lastName', 'auth.form.lastName.validation.base'),
    }),
    travel: Joi.object({
      ssn: Joi.string().required().empty().regex(REGEX_SSN).min(11).max(11).messages({
        'string.base': 'auth.form.ssn.validation.base',
        'string.empty': 'auth.form.ssn.validation.empty',
        'string.pattern.base': 'auth.form.ssn.validation.base',
        'string.min': 'auth.form.ssn.validation.base',
        'string.max': 'auth.form.ssn.validation.base',
        'any.required': '',
      }),
      firstName: nameValidation('firstName', 'auth.form.firstName.validation.base'),
      lastName: nameValidation('lastName', 'auth.form.lastName.validation.base'),
    }),
    content: Joi.object({
      ssn: Joi.string().required().empty().regex(REGEX_SSN).min(11).max(11).messages({
        'string.base': 'auth.form.ssn.validation.base',
        'string.empty': 'auth.form.ssn.validation.empty',
        'string.pattern.base': 'auth.form.ssn.validation.base',
        'string.min': 'auth.form.ssn.validation.base',
        'string.max': 'auth.form.ssn.validation.base',
        'any.required': '',
      }),
      firstName: nameValidation('firstName', 'auth.form.firstName.validation.base'),
      lastName: nameValidation('lastName', 'auth.form.lastName.validation.base'),
    }),
  },
  [FORM_ID.FIND]: {
    vehicle: Joi.object().keys({
      MotorRegistrationNumber: Joi.string()
        .required()
        .empty()
        .max(10)
        .regex(REGEX_PRODUCT_ID)
        .messages({
          'string.base': 'find.form.MotorRegistrationNumber.validation.base',
          'string.empty': 'find.form.MotorRegistrationNumber.validation.base',
          'string.pattern.base': 'find.form.MotorRegistrationNumber.validation.base',
          'any.required': '',
        }),
    }),
  },
  [FORM_ID.CHANGE]: {
    travel: Joi.object().keys({
      TravelCoverage: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      TravelCoverArea: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      TravelPreviousClaims: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
    vehicle: Joi.object().keys({
      CarPackage: Joi.string().required().max(4).messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      CarComprehensiveDeductible: Joi.string()
        .optional()
        .valid(null, '', '4000', '6000', '8000', '12000', '16000')
        .min(4)
        .max(5)
        .messages({
          'string.base': `Base validator`,
          'string.valid': `Base validator`,
        }),
      AnnualMileage: Joi.number().required().empty().min(1).messages({
        'number.base': `Base validator`,
        'number.empty': `Empty validator`,
        'number.min': `Min validator`,
        'any.required': `required`,
      }),
      CarBonus: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      YoungDrivers: Joi.boolean().required().empty().messages({
        'date.base': `Base validator`,
        'date.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
    content: Joi.object().keys({
      SumInsuredContents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      Alarm: Joi.boolean().optional().allow(null),
      DeductibleContents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
      Residents: Joi.string().required().empty().messages({
        'string.base': `Base validator`,
        'string.empty': `Empty validator`,
        'any.required': `required`,
      }),
    }),
  },
  [FORM_ID.COMPARE_AUTH]: {
    vehicle: Joi.object().keys({
      phoneNumber: Joi.string()
        .required()
        .empty()
        .min(8)
        .max(8)
        .regex(REGEX_MULTIPLE_DIGITS)
        .messages({
          'string.base': 'compareAuth.form.validation.phoneNumber',
          'string.min': 'compareAuth.form.validation.phoneNumber',
          'string.max': 'compareAuth.form.validation.phoneNumber',
          'string.empty': 'compareAuth.form.validation.phoneNumber',
          'any.required': 'compareAuth.form.validation.phoneNumber',
          'string.pattern.base': 'compareAuth.form.validation.phoneNumber',
        }),
      birthDate: Joi.string()
        .required()
        .empty()
        .min(6)
        .max(6)
        .regex(REGEX_MULTIPLE_DIGITS)
        .messages({
          'string.base': 'compareAuth.form.validation.birthDate',
          'string.min': 'compareAuth.form.validation.birthDate',
          'string.max': 'compareAuth.form.validation.birthDate',
          'string.empty': 'compareAuth.form.validation.birthDate',
          'any.required': 'compareAuth.form.validation.birthDate',
          'string.pattern.base': 'compareAuth.form.validation.birthDate',
        }),
    }),
  },
  [FORM_ID.OWNERSHIP]: {
    vehicle: Joi.object().keys({
      ownership: Joi.string()
        .required()
        .empty()
        .valid('1')
        .messages({
          'any.only': `ownership.form.ownership.validation.invalid?email=${brandConfig.EMAIL}`,
        }),
      owner: Joi.when('ownership', {
        is: Joi.string().valid('1'),
        then: Joi.string().required().empty().valid('1', '2').messages({
          'string.base': `base`,
          'string.valid': `valid or not?`,
          'string.empty': `empty`,
          'string.required': `required`,
        }),
        otherwise: Joi.valid(null),
      }),
      ssn: Joi.when('owner', {
        is: Joi.string().valid('2'),
        then: Joi.string().required().empty().regex(REGEX_SSN).messages({
          'string.base': 'ownership.form.ssn.validation.base',
          'string.empty': 'ownership.form.ssn.validation.empty',
          'string.pattern.base': 'ownership.form.ssn.validation.base',
          'any.required': 'ownership.form.ssn.validation.base',
        }),
        otherwise: Joi.valid(),
      }),
      fullName: Joi.when('owner', {
        is: Joi.string().valid('2'),
        then: nameValidation('fullName', 'ownership.form.fullName.validation.base', 2),
      }),
      otherwise: Joi.valid(),
    }),
  },
  [FORM_ID.VERIFY]: {
    travel: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        insuranceCompany: Joi.number().required().empty().messages({
          'number.base': ``,
          'any.required': ``,
        }),
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'verify.form.policyDate.validation.base',
            'date.empty': 'verify.form.policyDate.validation.base',
            'date.max': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'verify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'verify.form.email.validation.base',
            'string.email': 'verify.form.email.validation.base',
            'string.empty': 'verify.form.email.validation.base',
            'any.required': 'verify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'verify.form.phone.validation.base',
            'string.length': 'verify.form.phone.validation.base',
            'string.pattern.base': 'verify.form.phone.validation.base',
            'any.required': 'verify.form.phone.validation.base',
          }),
        agreementConfirm: Joi.boolean().required().valid(true).messages({
          'any.only': '',
          'any.required': '',
        }),
      })
    },
    content: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        insuranceCompany: Joi.number().required().empty().messages({
          'number.base': ``,
          'any.required': ``,
        }),
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'verify.form.policyDate.validation.base',
            'date.empty': 'verify.form.policyDate.validation.base',
            'date.max': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'verify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'verify.form.email.validation.base',
            'string.email': 'verify.form.email.validation.base',
            'string.empty': 'verify.form.email.validation.base',
            'any.required': 'verify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'verify.form.phone.validation.base',
            'string.length': 'verify.form.phone.validation.base',
            'string.pattern.base': 'verify.form.phone.validation.base',
            'any.required': 'verify.form.phone.validation.base',
          }),
        agreementConfirm: Joi.boolean().required().valid(true).messages({
          'any.only': '',
          'any.required': '',
        }),
      })
    },
    vehicle: ({ min, max }) => {
      const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
      const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

      return Joi.object().keys({
        insuranceCompany: Joi.number().required().empty().allow(null).messages({
          'number.base': ``,
          'any.required': ``,
        }),
        policyDate: Joi.date()
          .required()
          .empty()
          .min(min)
          .max(max)
          .messages({
            'date.base': 'verify.form.policyDate.validation.base',
            'date.empty': 'verify.form.policyDate.validation.base',
            'date.max': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'date.min': `verify.form.policyDate.validation.date?min=${formattedMin}&max=${formattedMax}`,
            'any.required': 'verify.form.policyDate.validation.base',
          }),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .empty()
          .messages({
            'string.base': 'verify.form.email.validation.base',
            'string.email': 'verify.form.email.validation.base',
            'string.empty': 'verify.form.email.validation.base',
            'any.required': 'verify.form.email.validation.base',
          }),
        phone: Joi.string()
          .length(8)
          .pattern(/^[0-9]{8}$/)
          .required()
          .messages({
            'string.base': 'verify.form.phone.validation.base',
            'string.length': 'verify.form.phone.validation.base',
            'string.pattern.base': 'verify.form.phone.validation.base',
            'any.required': 'verify.form.phone.validation.base',
          }),
        agreementConfirm: Joi.boolean().required().valid(true).messages({
          'any.only': '',
          'any.required': '',
        }),
      })
    },
  },
  [FORM_ID.MILEAGE]: {
    vehicle: Joi.object().keys({
      mileage: Joi.string().required().empty().regex(REGEX_MILEAGE_FIELD).min(1).max(6).messages({
        'string.base': 'mileage.form.mileage.validation.base',
        'string.empty': 'mileage.form.mileage.validation.empty',
        'string.max': 'mileage.form.mileage.validation.pattern',
        'string.min': 'mileage.form.mileage.validation.pattern',
        'string.pattern.base': 'mileage.form.mileage.validation.pattern',
        'any.required': ``,
      }),
      readDate: Joi.date().required().empty().max('now').messages({
        'date.base': 'mileage.form.readDate.validation.base',
        'date.empty': 'mileage.form.readDate.validation.empty',
        'date.max': 'mileage.form.readDate.validation.max',
        'any.required': 'mileage.form.readDate.validation.empty',
      }),
    }),
  },
})

export default quoteAndBuyFormValidation
