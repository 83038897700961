import { AuthMethod } from '../constants/auth-constants'
import { ProductName } from './interface/quote.interface'
import { CountyCode } from '../config'
import { ErrorCode, ErrorStatus } from '../constants'

export enum REST_V2_ENDPOINTS {
  userAddress = '/userAddress',
  userSession = '/userSession',
  quote = '/quote',
  policy = '/policy',
  claim = '/claim',
  insurely = '/insurely',
  agent = '/agent',
  datasource = '/datasource',
  party = '/party',
  person = '/person',
  auth = '/auth',
  authBankId = '/auth-bank-id',
  clearCookie = '/clearCookies',
  getVippsLink = '/get-vipps-link',
}

export type UserAddressItemResponseType = {
  address_type: string
  country: string
  address: string
  postNumber: string
  city: string
}

export type UserAddressResponseType = { addresses: UserAddressItemResponseType[] }

export type EncryptedDataType = {
  iv: string
  content: string
}

// General Quote Type
export type QuoteV2Type = {
  QuoteId: number
  ContactAllowed: boolean
  QuoteStatus: number
  MotorRegistrationNumber: string
  ManufacuringYear: number
  CarMake: string
  CarModel: string
  CarBonus: string
  PreviousInsuranceCompany: string
  PreviousInsuranceCompanyId: string
  PreviousCompanyName: string
  PreviousCompanyId: string
  CarPackage: string
  AnnualMileage: number
  YoungDrivers: boolean
  SuperCoverage: boolean
  CarComprehensiveDeductible: number
  AnnualTaxMotorInsurance: number
  RoundedAnnualPremium: number
  Premium: number
  PaymentPeriod: number
  EarliestStartDateOfInsurance: string
  IOwnVehicle: boolean
  IsPolicyHolderInTFF: boolean
  TravelCoverage: string
  TravelCoverArea: string
  TravelMoveInsurance: boolean
  TravelPreviousClaims: string
  SumInsuredContents: string
  Residents: string
  DeductibleContents: string
  Naturskadepremie: number
  Address1: string
  PostNumber: string
  Alarm: boolean
  City: string
  IntermediaryId: string
  AcceptedPolicyNumber: string
  PolicyNumber: string
  InsuredFrom: string
  NewOwnerRegistrationNumber: string
  NewOwnerName: string
  InsurelyPremium: number
  SellingChannel: string
  CalculatedPrices: {
    taxAnnual: number
    taxMonthly: number
    priceTotal: number
    priceAnnualWithTax: number
    priceAnnual: number
    priceMonthly: number
    priceMonthlyWithTax: number
  }
  canVerify: boolean
  canComparePrice: boolean
  displayPrevCompanyField: boolean
  encrypted: EncryptedDataType
  prevInsuranceCompanyId: string
  country: CountyCode
  productName: ProductName
}

export type QuoteV2UpdateResponseType = {
  data: Partial<QuoteV2Type>
}

export type QuoteV2ResponseType = {
  data: Partial<QuoteV2Type>
}

// General Claim Type
export type ClaimV2Type = {
  ClaimId: number
  ClaimStatus: number
  DefinitionId: number
  VersionId: number
  PartId: number
  ConfigurationId: number
  ParentPartId: number
  ParentId: number
  RootId: number
  MotorRegistrationNumber: string
  PolicyNumber: string
  QuestionAnyOtherInformationWeShouldKnowOf: string
  QuestionChooseCorrectClaim: string
  QuestionDidAnyoneGetInjured: string
  QuestionEstimatedValue: number
  QuestionEstimatedValueCurrency: number
  QuestionHasItOccurredDispute: boolean
  QuestionHaveYouFilledOutClaimFormOnPaper: boolean
  QuestionHowDidTheAccidentHappen: string
  QuestionHowManyCarsWereInvolved: string
  QuestionHowOldIsTheItem: number
  QuestionInYourOpinionWhatIsMostCorrect: string
  QuestionIsItReportedToThePolice: boolean
  QuestionTheOtherPartsLicensPlateNumber: string
  QuestionWasAnyoneInYourCarInjured: string
  QuestionWhatDidYouHit: string
  QuestionWhatHappened: string
  QuestionWhatHasHappened: string
  QuestionWhatHasHappenedOtherReasons: string
  QuestionWhatIsTheDisputedAmount: number
  QuestionWhatIsTheDisputedAmountCurrency: number
  QuestionWhatIsTheReportNumber: string
  QuestionWhatKindOfAnimalDidYouHit: string
  QuestionWhatWasStolen: string
  QuestionWhatWasStolenFromTheCar: string
  QuestionWhenDidDisputeOccurred: string
  QuestionWhenDidItHappened: string
  QuestionWhereDidItHappened: string
  QuestionReasonForFire: string
  Time: string
}

export type ClaimV2ResponseType = {
  data: Partial<ClaimV2Type>
}

export type CorrespondenceV2Type = {
  ReferenceRootInstanceId: number
  CorrespondenceId: number
}

// General Policy Type
export interface PolicyV2Type extends QuoteV2Type {
  AgreementName: string
  CarModelType: string
  CorrespondenceId: number
  InsertDatetime: string
  InsuranceNumber: string
  InsuredFrom: string
  InsuredTo: string
  ManufacturingYear: number
  PolicyNumber: string
  PolicyStatus: number
  PolicyId: number
  OdometerReading: number
  DateOdometerReading: string
  CalculatedPrices: {
    taxAnnual: number
    taxMonthly: number
    priceTotal: number
    priceAnnualWithTax: number
    priceAnnual: number
    priceMonthly: number
    priceMonthlyWithTax: number
  }
  Correspondence: CorrespondenceV2Type[]
  VersionNo: number
  Versions: Partial<PolicyV2Type>[]
  RoundedPremium: number
}

// changeDate is a special field that is used for the policy change request
export type PolicyV2ChangeRequestType = PolicyV2Type & { changeDate: string }

export type PolicyAllV2ResponseType = {
  data: Partial<PolicyV2Type>[]
}

export type PolicyV2ResponseType = {
  data: Partial<PolicyV2Type>
}

export type PolicyDocumentV2ResponseType = {
  data: {
    id: string
    Name: string
    MIMEType: string
    Content: string
    Extension: string
  }
}

export type CancelInsurancesResponseType = {
  cancelledInsurances: { collectionId: string; externalId: string; documentId: string }[]
  cancelStatus: {
    id: string
    insurance: { collectionId: string; externalId: string }
    status: string
    isSignedAndSealed: boolean
    createdAt: string
    updatedAt: string
    documentUrl: string
    terminationDate: string
    agent: unknown
  }
}

export type AgentPersonLoginResponseType = {
  token: string
  kid: string
  authMethod: AuthMethod
  agentId: string
  error?: string
  details?: string
}

export type ProductPropV2Type = {
  id: string
  values: { [key: string]: string; name: string; value: string }[]
}

export type ProductPropsV2ResponseType = {
  data: Record<string, ProductPropV2Type>
}

export type ProductPropsV2Type = ProductPropsV2ResponseType['data']

export type InsuranceCompanyV2Type = {
  PartyId: string
  OrganizationName: string
}

export type InsuranceCompaniesV2ResponseType = {
  data: InsuranceCompanyV2Type[]
}

export type PartyV2Type = {
  PartyId: number
  Email: string
  MobilePhone: string
  Language: number
  FirstName: string
  LastName: string
}

export type PartyV2UpdateResponseType = {
  data: Partial<PartyV2Type>
}

export type VehicleV2Type = {
  MotorRegistrationNumber: string
  ManufacturingYear: string
  CarMake?: string
  CarModel?: string
}

export type PersonAllVehiclesV2ResponseType = {
  data: VehicleV2Type[]
}

export type AuthRegularV2InputType = {
  ssn: string
  firstName?: string
  lastName?: string
  regNum?: string // CircleK specific optional field
  reCaptchaToken?: string
}

export type AuthRegularV2ResponseType = {
  data: {
    token: string
    authMethod: AuthMethod
    kid: string
  }
}

export type SignOutV2ResponseType = {
  data: {
    sessionDropped: boolean
  }
}

export type ResponseErrorV2Type = {
  status: ErrorStatus
  response?: {
    details: string[]
    error: {
      code: ErrorCode
      message: string
    }
  }
}

export type RequestV2BodyType = Record<string, unknown>
export type RequestV2ParamsType = Record<string, unknown>
