import React, { FC, useState } from 'react'
import { Hidden, CardContent, Divider } from '@material-ui/core'
import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { CoverageDetailedInfo } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import useContentStyles from '../../hooks/shared/useContentStyles'
import {
  PolicyV2ResponseType,
  PolicyV2Type,
  ProductPropsV2Type,
  ProductPropV2Type,
  ResponseErrorV2Type,
} from '../../types/ApiV2'
import { FSMService } from '../../FSM'
import { FORM_ID, productPropsFieldNamesMap } from '../../constants'
import FormBuilder from './forms/FormBuilder'
import { UseMutateFunction } from '@tanstack/react-query'
import { FormState } from '../../types/interface/form.interface'
import { getDataComponentName, getFlowName, getProductName } from '../../util'
import { get } from 'lodash'
import FormConfig from 'FormConfig'

interface PolicyChangeCardProps {
  policy: Partial<PolicyV2Type>
  updatePolicy: UseMutateFunction<
    PolicyV2ResponseType,
    ResponseErrorV2Type,
    Partial<PolicyV2Type>,
    unknown
  >
  productProps: ProductPropsV2Type
}

const PolicyChangeCard: FC<PolicyChangeCardProps> = ({ policy, updatePolicy, productProps }) => {
  const classes = useContentStyles({ isImage: true })
  const { t } = useTranslate(T.POLICY_CHANGE)
  const [coverageInfoOpened, setCoverageInfoOpened] = useState<boolean>(false)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [formValue, setFormValue] = useState<Partial<PolicyV2Type>>({})

  const flowName = getFlowName()
  const productName = getProductName()
  const formConfig = get(FormConfig, [flowName, FORM_ID.CHANGE, productName])
  const formInitialValues: { [key in keyof PolicyV2Type]?: unknown } = {}
  const formOptionsValues: { [key in keyof PolicyV2Type]?: ProductPropV2Type['values'] } = {}

  Object.keys(formConfig.fields).forEach((fieldName: keyof PolicyV2Type) => {
    // Conversion Quote field value to string if it is number for rendering in form
    // Fill form initial values from the policy data
    formInitialValues[fieldName] =
      typeof policy[fieldName] === 'number' ? String(policy[fieldName]) : policy[fieldName]

    // Fill options values for each field (in case they are available in productProps)
    const productPropMappedFieldName = productPropsFieldNamesMap[fieldName]
    formOptionsValues[fieldName] = productProps[productPropMappedFieldName]?.values
  })

  const submitHandler = async (values: Partial<PolicyV2Type>) => {
    await updatePolicy(values)
  }

  const coverageInfo = (
    <CoverageDetailedInfo
      send={FSMService.flowService.send}
      state={FSMService.flowService.state.value}
      coverageType={formValue.CarPackage}
      onClose={() => setCoverageInfoOpened(false)}
      translationCondition={policy.SuperCoverage ? 'isSuper' : 'isNotSuper'}
    />
  )

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <CardContent className={classes.twoCol}>
        <section
          className={classes.colLeft}
          data-testid={'quote-info-block'}
          style={{ justifyContent: 'start' }}
        >
          <div className={classes.column} data-testid={'coverage-data'} style={{ marginTop: 0 }}>
            <FormBuilder
              id={FORM_ID.POLICY_CHANGE}
              filledFieldsData={formValue}
              handleBtnDisabled={setBtnDisabled}
              optional={btnDisabled}
              initialValues={formInitialValues}
              options={formOptionsValues}
              setFormState={({ values }: FormState<never>): void => setFormValue(values)}
              submitText={t('form.submitText')}
              submitHandler={submitHandler}
              isFormControllable
            />
          </div>
        </section>
        <Hidden smDown>
          <div className={classes.colRight}>{coverageInfo}</div>
        </Hidden>
        <Hidden mdUp>
          <Divider />
          {coverageInfoOpened && coverageInfo}
        </Hidden>
      </CardContent>
    </div>
  )
}

export default PolicyChangeCard
