import React, { FC, useState, useEffect } from 'react'
import { Events, FSMCommonTransitions } from '../shared/constants'
import { FSMRouterProvider } from '../FSMContext'
import { FSMStateType } from '../../types/interface/fsm.interface'
import { FSMService } from '../index'

interface FSMProviderProps {
  children: React.ReactNode
}

const FSMProvider: FC<FSMProviderProps> = ({ children }) => {
  const [state, setState] = useState<FSMStateType>()

  useEffect(() => {
    // Handle browser's back button (triggers FSM event BACK)
    window.onpopstate = () => FSMService.flowService.send(Events.BACK)

    // Handle browser back button event after returning from external domains (other domain triggers 'unload')
    window.addEventListener('beforeunload', () => {
      const stateValue = FSMService.flowService.state.value

      // Case from Vipps to extraOffers
      if (
        (FSMService.flowService.state.value as Record<string, string>)?.[
          FSMCommonTransitions.QB_EXTRA_OFFERS
        ]
      ) {
        FSMService.flowService.send(Events.BACK)
      }

      // Case of error
      if ([FSMCommonTransitions.ERROR].includes(stateValue as FSMCommonTransitions)) {
        FSMService.flowService.send(Events.BACK)
      }
    })
  }, [])

  useEffect(() => {
    FSMService.onTransitionCallback = setState
    FSMService.restart()

    return () => {
      FSMService.flowService.stop()
    }
  }, [])

  return (
    <FSMRouterProvider
      value={{
        currentState: state,
        service: FSMService.flowService,
      }}
    >
      {children}
    </FSMRouterProvider>
  )
}

export default FSMProvider
