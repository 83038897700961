import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { FlowServiceType } from '../../types/interface/fsm.interface'
import { useSelector } from '@xstate/react'
import { AuthMethod } from '../../constants/auth-constants'
import { SESSION_ID_KEY } from '../../config'
import { Typography } from '@dg-shared/index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    agentStatus: {
      top: 0,
      left: 0,
      padding: '1px 5px',
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(0.5),
      position: 'absolute',
      background: theme.brand.colors.background,
      color: theme.brand.colors.headingMain,
      zIndex: 10,
    },
  })
)

type AgentStatusPropsType = {
  flowService: FlowServiceType
}

const AgentStatus: FC<AgentStatusPropsType> = ({ flowService }) => {
  const classes = useStyles()
  const isAgent =
    useSelector(flowService, (state) => state.context.authMethod === AuthMethod.AGENT) ||
    !!sessionStorage.getItem(SESSION_ID_KEY)

  return isAgent ? (
    <Typography className={classes.agentStatus} size={'xSmall'}>
      Agent
    </Typography>
  ) : null
}

export default AgentStatus
