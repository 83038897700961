import React, { FC, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { ProductName } from '../../../types/interface/quote.interface'
import { IS_LOCAL } from '../../../config'
import { FSMMatch, FSMSwitch } from '../../../FSM'
import { MATCH_COMPOUND } from '../../../FSM/shared/constants'
import { getFlowName, getProductName } from '@dg-util'
import { LDCardTwoCol, LMBgHeader, withHidden } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'

import useLogic from './useLogic'
import AuthDecision from './AuthDecision'
import AuthRegular from './AuthRegular'
import Loading from '../Loading/Page'
import { logoMax, logoMin } from 'BrandedImages'
import StandardsButton from '@dg-shared/StandardsButton'
import FNOLDevButton from '@dg-shared/FNOLDevButton'
import PlaygroundButton from '@dg-shared/PlaygroundButton'
import AuthAgent from './AuthAgent'
import AuthStrategyTitle from 'components/AuthStrategyTitle'
import AuthStrategyDisclaimer from 'components/AuthStrategyDisclaimer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnToggle: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      color: theme.palette.secondary.contrastText,
    },
    textFooter: {
      textAlign: 'center',
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
    mollerMobilityLogo: {
      width: 239,
      height: 28,
      marginTop: -4,
      marginLeft: 10,
    },
    link: {
      ...theme.brand.assets.layout?.buttonLink,
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

window.grecaptcha = (window as Window).grecaptcha || {}

const AuthStrategy: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.AUTH)
  const classes = useStyles()
  const { loading, errorType, errorVipps, vippsLink, authHandler } = useLogic(service)
  const [agentLoading, setAgentLoading] = useState<boolean>(false)
  const [agentError, setAgentError] = useState<string>('')

  const toggleProduct = () => {
    const productName = getProductName()

    const switchProduct = () => {
      const newProductName =
        productName === ProductName.VEHICLE ? ProductName.TRAVEL : ProductName.VEHICLE
      window.location.search = `?flow=${getFlowName()}&product=${newProductName}`
    }

    if (IS_LOCAL) {
      return (
        <Button className={classes.btnToggle} onClick={switchProduct}>
          {`[${productName}] toggle`}
        </Button>
      )
    }
  }

  const errorEventType = errorType || agentError

  const routes = (
    <FSMSwitch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_CHOICE}>
        <AuthDecision
          textBtnAuthRegular={t(`decision.textBtn`)}
          linkVipps={vippsLink}
          errorVipps={errorVipps}
        />
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_REGULAR}>
        <AuthRegular handleAuth={authHandler} />
      </FSMMatch>
      <FSMMatch is={MATCH_COMPOUND.AUTH_AGENT}>
        <AuthAgent
          handleLoading={(isLoading) => setAgentLoading(isLoading)}
          handleError={(agentError) => setAgentError(agentError)}
        />
      </FSMMatch>
    </FSMSwitch>
  )

  if (loading || agentLoading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        headerSubTitle={t('subtitle')}
        textLogoAlt={t('title')}
        imageLogo={logoMin}
        brandPageBackground='mainMobile'
      >
        <AuthStrategyDisclaimer errorEventType={errorEventType} fsmState={currentState} />
        {routes}
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='main'
        brandPageBackgroundCard='mainCard'
      >
        {toggleProduct()}
        <StandardsButton />
        <FNOLDevButton />
        <PlaygroundButton />
        <section id='auth-strategy-text'>
          <AuthStrategyTitle />
          <AuthStrategyDisclaimer errorEventType={errorEventType} fsmState={currentState} />
        </section>
        {routes}
      </DesktopScreen>
    </>
  )
}

export default AuthStrategy
