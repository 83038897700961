import { FormConfigType, PageForm } from '../../../types/interface/form.interface'
import {
  BONUS_TEST_ID,
  FORM_ID,
  FORMAT_DAY_MONTH_YEAR_DOT,
  MILEAGE_TEST_ID,
  OFFSET_90_DAYS,
  PREVIOUS_INSURANCE_COMPANY_TEXT_LOCATOR,
  POLICY_START_DATE_TEXT_LOCATOR,
} from '../../../constants'
import {
  RFFCustomDatePicker,
  RFFCustomSelectV2,
  RFFCustomSwitch,
  RFFCustomTextField,
  RFFFormControlRadio,
} from '@dg-shared'
import AuthCustomLabel from '../../../pages/qb/Auth/Content/AuthCustomLabel'
import { normalizeFormat } from '@dg-util'
import { toUpperCase } from '../../../service/util.service'
import CompanySelect from '../../../pages/qb/Verify/CompanySelect'
import PhoneFieldLabel from '../../../pages/qb/Verify/Content/PhoneFieldLabel'

export const generalVerifyFormConfig: PageForm = {
  id: FORM_ID.VERIFY,
  fields: {
    insuranceCompany: {
      name: 'insuranceCompany',
      component: CompanySelect,
      componentProps: {
        defaultItemText: 'form.insuranceCompany.defaultItem',
        labelId: PREVIOUS_INSURANCE_COMPANY_TEXT_LOCATOR,
      },
    },
    policyDate: {
      name: 'policyDate',
      component: RFFCustomDatePicker,
      componentProps: {
        fieldInputVariant: 'outlined',
        fieldMinDate: Date.now(),
        fieldMaxDate: new Date(Date.now() + OFFSET_90_DAYS),
        formatType: FORMAT_DAY_MONTH_YEAR_DOT,
        labelId: POLICY_START_DATE_TEXT_LOCATOR,
      },
    },
    email: {
      name: 'email',
      type: 'email',
      component: RFFCustomTextField,
    },
    phone: {
      name: 'phone',
      type: 'tel',
      component: RFFCustomTextField,
      customLabel: PhoneFieldLabel,
      componentProps: {
        inputAdornmentText: '+47',
      },
    },
  },
  initialValues: {},
}
export const vehicleChangeFormConfig: PageForm = {
  id: FORM_ID.CHANGE,
  fields: {
    CarPackage: {
      name: 'CarPackage',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
    CarComprehensiveDeductible: {
      name: 'CarComprehensiveDeductible',
      component: RFFCustomSelectV2,
      dependency: [
        {
          fieldName: 'CarPackage',
          value: ['3', '4'],
        },
      ],
      initialValue: '4000',
      componentProps: {
        fetchOptions: true,
      },
    },
    CarBonus: {
      name: 'CarBonus',
      component: RFFCustomSelectV2,
      testId: BONUS_TEST_ID,
      componentProps: {
        fetchOptions: true,
      },
    },
    AnnualMileage: {
      name: 'AnnualMileage',
      component: RFFCustomSelectV2,
      testId: MILEAGE_TEST_ID,
      componentProps: {
        fetchOptions: true,
      },
    },
    YoungDrivers: {
      name: 'YoungDrivers',
      type: 'checkbox',
      component: RFFCustomSwitch,
      labelPlacement: 'top',
    },
  },
  initialValues: {
    CarPackage: '2',
  },
}
export const travelChangeFormConfig: PageForm = {
  id: FORM_ID.CHANGE,
  fields: {
    TravelCoverage: {
      name: 'TravelCoverage',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
    TravelCoverArea: {
      name: 'TravelCoverArea',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
    TravelPreviousClaims: {
      name: 'TravelPreviousClaims',
      component: RFFCustomSelectV2,
      testId: 'TravelPreviousClaims_TEST_ID',
      componentProps: {
        fetchOptions: true,
      },
    },
  },
  initialValues: {
    TravelCoverage: 'Family',
    TravelCoverArea: 'Nordics',
    TravelPreviousClaims: '0',
  },
}
export const contentChangeFormConfig: PageForm = {
  id: FORM_ID.CHANGE,
  fields: {
    SumInsuredContents: {
      name: 'SumInsuredContents',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
    Alarm: {
      name: 'Alarm',
      type: 'checkbox',
      component: RFFCustomSwitch,
      labelPlacement: 'top',
      componentProps: {
        fetchOptions: true,
      },
    },
    DeductibleContents: {
      name: 'DeductibleContents',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
    Residents: {
      name: 'Residents',
      component: RFFCustomSelectV2,
      componentProps: {
        fetchOptions: true,
      },
    },
  },
  initialValues: {},
}

const QuoteAndBuyFormConfig: FormConfigType = {
  [FORM_ID.AUTH]: {
    vehicle: {
      id: FORM_ID.AUTH,
      fields: {
        ssn: {
          name: 'ssn',
          type: 'tel',
          component: RFFCustomTextField,
          customLabel: AuthCustomLabel,
        },
        firstName: {
          name: 'firstName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
        lastName: {
          name: 'lastName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
      },
      initialValues: {},
    },
    travel: {
      id: FORM_ID.AUTH,
      fields: {
        ssn: {
          name: 'ssn',
          type: 'tel',
          component: RFFCustomTextField,
          customLabel: AuthCustomLabel,
        },
        firstName: {
          name: 'firstName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
        lastName: {
          name: 'lastName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
      },
      initialValues: {},
    },
    content: {
      id: FORM_ID.AUTH,
      fields: {
        ssn: {
          name: 'ssn',
          type: 'tel',
          component: RFFCustomTextField,
          customLabel: AuthCustomLabel,
        },
        firstName: {
          name: 'firstName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
        lastName: {
          name: 'lastName',
          component: RFFCustomTextField,
          type: 'text',
          format: normalizeFormat,
          formatOnBlur: true,
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.FIND]: {
    vehicle: {
      id: FORM_ID.FIND,
      fields: {
        MotorRegistrationNumber: {
          name: 'MotorRegistrationNumber',
          component: RFFCustomTextField,
          type: 'text',
          format: toUpperCase,
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.CHANGE]: {
    travel: travelChangeFormConfig,
    vehicle: vehicleChangeFormConfig,
    content: contentChangeFormConfig,
  },
  [FORM_ID.OWNERSHIP]: {
    vehicle: {
      id: FORM_ID.OWNERSHIP,
      fields: {
        ownership: {
          name: 'ownership',
          type: 'radio',
          component: RFFFormControlRadio,
          errorMsg: 'error',
          componentProps: {
            testId: 'vehicle-ownership-change-owner',
            options: {
              yes: {
                value: '1',
                text: 'yes',
              },
              no: {
                value: '2',
                text: 'no',
              },
            },
          },
        },
        owner: {
          name: 'owner',
          type: 'radio',
          component: RFFFormControlRadio,
          dependency: [
            {
              fieldName: 'ownership',
              value: '1',
            },
          ],
          componentProps: {
            testId: 'vehicle-ownership-going-own',
            options: {
              yes: {
                value: '1',
                text: 'yes',
              },
              no: {
                value: '2',
                text: 'no',
              },
            },
          },
        },
        ssn: {
          name: 'ssn',
          type: 'number',
          component: RFFCustomTextField,
          dependency: [
            {
              fieldName: 'ownership',
              value: '1',
            },
            {
              fieldName: 'owner',
              value: '2',
            },
          ],
        },
        fullName: {
          name: 'fullName',
          type: 'text',
          component: RFFCustomTextField,
          dependency: [
            {
              fieldName: 'ownership',
              value: '1',
            },
            {
              fieldName: 'owner',
              value: '2',
            },
          ],
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.COMPARE_AUTH]: {
    vehicle: {
      id: FORM_ID.COMPARE_AUTH,
      fields: {
        phoneNumber: {
          name: 'phoneNumber',
          type: 'tel',
          component: RFFCustomTextField,
        },
        birthDate: {
          name: 'birthDate',
          component: RFFCustomTextField,
          type: 'number',
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.VERIFY]: {
    travel: generalVerifyFormConfig,
    content: generalVerifyFormConfig,
    vehicle: generalVerifyFormConfig,
  },
  [FORM_ID.MILEAGE]: {
    vehicle: {
      id: FORM_ID.MILEAGE,
      fields: {
        mileage: {
          name: 'mileage',
          type: 'tel',
          component: RFFCustomTextField,
          componentProps: {
            autoFocus: true,
          },
        },
        readDate: {
          name: 'readDate',
          type: 'tel',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldMaxDate: Date.now(),
            formatType: FORMAT_DAY_MONTH_YEAR_DOT,
          },
        },
      },
      initialValues: {},
    },
  },
}

export default QuoteAndBuyFormConfig
