import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { StylesProps } from '../../../types/interface/styles.interface'
import { Typography } from '@dg-shared'
import { TestPropsType, TranslatedTextType } from '../../../types'
import { ThemeBrandPageBackgroundNameType } from 'BrandTheme/types'
import MobileHeaderText from 'components/MobileHeaderText'
import { getProductName } from '@dg-util'

export const useLMBgHeaderStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: (props: StylesProps) => ({
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        minHeight: `${props.height}px`,
        backgroundColor: theme.brand.colors.clean,
      }),
      page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        ...theme.brand.assets.layout?.LMBgHeaderPage,
        [theme.breakpoints.down('sm')]: {
          flex: 1,
          paddingTop: theme.spacing(1),
        },
      },
      header: (props: StylesProps) => ({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: theme.spacing(2),
        minHeight: 200,
        justifyContent: 'space-between',
        borderRadius: '0 0 0 0',
        position: 'relative',
        ...theme.brand.assets.pageBackgrounds[props.brandPageBackground],
        ...theme.brand.assets.layout?.LMBgHeaderHeader,
      }),
      logoWrapper: {
        display: 'flex',
        flex: 0,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          ...theme.brand.assets.layout?.mobileCustomLogoWrapper,
        },
      },
      logoImage: (props: StylesProps) => ({
        alignSelf: 'start',
        height: props.logoImageHeight ? props.logoImageHeight : '28px',
      }),
      textFooter: {
        textAlign: 'center',
        padding: `${theme.spacing(1)}px 0 0`,
        marginBottom: '0',
      },
    }),
  { index: 1 }
)

export type LMBgHeaderProps = {
  headerTitle?: TranslatedTextType
  headerClass?: string
  headerSubTitle?: TranslatedTextType
  textLogoAlt: TranslatedTextType
  textFooter?: TranslatedTextType
  headerContainerClass?: string
  containerClass?: string
  pageClass?: string
  imageGradient?: boolean
  imageLogo: string
  brandPageBackground: ThemeBrandPageBackgroundNameType
  testProps?: TestPropsType
}

const LMBgHeader: FC<LMBgHeaderProps> = ({
  headerTitle,
  headerSubTitle,
  headerClass = '',
  textFooter,
  headerContainerClass = '',
  containerClass = '',
  pageClass = '',
  textLogoAlt,
  imageLogo,
  children,
  imageGradient,
  brandPageBackground,
  testProps,
}) => {
  const classes = useLMBgHeaderStyles({
    brandPageBackground,
    leftColGradientReverse: imageGradient,
    height: window.innerHeight,
    productName: getProductName(),
  })

  return (
    <Grid container className={`${classes.container} ${containerClass}`}>
      <div
        data-testid='mobile-header'
        {...testProps}
        className={`${classes.header} ${headerContainerClass}`}
      >
        <div className={classes.logoWrapper}>
          <img
            id={`mobile-header-img`}
            className={classes.logoImage}
            src={imageLogo}
            alt={`${textLogoAlt} Logo`}
          />
        </div>
      </div>
      <MobileHeaderText
        headerTitle={headerTitle}
        headerSubTitle={headerSubTitle}
        headerClass={headerClass}
      />
      <Grid item xs={12} className={`${classes.page} ${pageClass}`}>
        {children}
        {!!textFooter && (
          <Typography size='small' className={classes.textFooter} color='textDisclaimer'>
            {textFooter}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default LMBgHeader
